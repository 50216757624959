import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SalesOrderPendingComponent } from "./salesorder-pending.component";

@NgModule({
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [SalesOrderPendingComponent],
  exports: [SalesOrderPendingComponent]
})
export class SalesOrderPendingModule {
  static forRoot(): ModuleWithProviders<SalesOrderPendingModule> {
    return {
      ngModule: SalesOrderPendingModule,
    };
  }
}
