import { Component, Output, EventEmitter, Injector, ViewChild } from "@angular/core";
import { PagedListingComponentBase } from "../../../paged-list-component-base";
import { GenericPopUpSettings } from "../generic-grid/generic-popup-grid.component";
import { NotificationService } from "./notification.service";
import { Router } from "@angular/router";
import { MasterRepo } from "@app/_services/masterRepo.service";
import { SalesOrderPendingComponent } from "../salesorder-pending-popup/salesorder-pending.component";

@Component({
  selector: "notification-popup-grid",
  templateUrl: "./notification-popup-grid.component.html",
})
export class NotificationPopUpComponent extends PagedListingComponentBase {
  /** List Declaration  */

  isActive: boolean = false;
  itemList: any[] = [];
  selectedRowIndex: number = 0;
  tabindex: string = "list";
  idForUpdate: any;
  flag:boolean

  /** Output  */

  @Output() onPopUpClose = new EventEmitter();
  @Output() onItemDoubleClick = new EventEmitter();
  @Output() notificationCountUpdate = new EventEmitter();

  @ViewChild("SalesOrderPendingList") SalesOrderPendingList: SalesOrderPendingComponent;

  /** Input  */

  popupsettings: GenericPopUpSettings;

  constructor(
    public injector: Injector,
    private notificationService: NotificationService,
    private router: Router,
    private masterService: MasterRepo,
  ) {
    super(injector);
    try {
      this.popupsettings = {
        title: "All Notifications",
        apiEndpoints: `/getAllNotificationPagedList`,
        defaultFilterIndex: 0,
        columns: [
          {
            key: "MESSAGE",
            title: "Details",
            hidden: false,
            noSearch: false
          },
          {
            key: "TIMESTAMP",
            title: "Notification At",
            hidden: false,
            noSearch: false
          }
        ]
      };
    }
    catch (ex) {
      console.log("CheckEr@", ex)
    }

    this.getNotificationCount()
    this.refreshPage();
    this.refresh();

  }

  show() {
    this.isActive = true;
    this.selectedRowIndex = 0;
  }

  getNotificationCount() {
    this.notificationService.getNotificationCount().subscribe(
      result => {
        let count = Number(result) ? Number(result) : 0;
        this.notificationCountUpdate.emit(count);
      },
      error => {
        this.notificationCountUpdate.emit(0);
      }
     
    );
    //NOTIFICATION HIT IN EVERY 10 MIN
    setTimeout(() => {
      if(this.masterService.flag){
        this.getNotificationCount()
      }
     }, 10000);
  }

  markAsRead(item) {
    console.log("mark as read", item);
    this.notificationService.idForUPdate = item.ID;
    this.redirectToBestRoute(item);
    this.hide();

    if (item.ISSEEN) return;
  }
  ngOnDestroy() {
    this.masterService.flag = false;
}
  redirectToBestRoute(item) {
   
    let caseString = "";
    let parentNotificationId = 0;
    let status = 0;
    let isDownloaded = 0;
    if (item) {
      caseString = item.TYPE?.toUpperCase();
      parentNotificationId = item.PARENTNOTIFICATIONID;
      status =
        item.ISAPPROVED ||
          item.ISAPPROVED == null ||
          item.ISAPPROVED == undefined
          ? 0
          : 1;
      isDownloaded =
        item.ISDOWNLOADED ||
          item.ISDOWNLOADED == 0 ||
          item.ISDOWNLOADED == null ||
          item.ISDOWNLOADED == undefined
          ? 0
          : 1;
    }
    switch (caseString) {
     
      case "PROFORMA INVOICE": {
        //statements;
     
        if (
          parentNotificationId == 0 ||
          parentNotificationId == undefined ||
          parentNotificationId == null
        ) {
          console.log(item)
          //redirect to performa
          this.router.navigate(
            ["/pages/transaction/sales/performa-invoice"],
            {
             
              queryParams: {
                voucher: 'PP01-MMX-2021',
                status: status,
                downloaded: isDownloaded,
                fromNotification: true
              }
            
            }
            
          );
        } else {
          this.router.navigate(
            ["/pages/transaction/sales/addsientry"],
            { queryParams: { voucher: item.REFNO, downloaded: isDownloaded, fromNotification: true } }
          );
          //redirect to sales -- approved
        }
        break;
      }
      case "PURCHASEORDER": {
        //statements;
        if (
          parentNotificationId == 0 ||
          parentNotificationId == undefined ||
          parentNotificationId == null
        ) {
          //redirect to performa
          this.router.navigate(
            ["/pages/transaction/sales/sales-order"],
            {
              queryParams: {
                voucher: item.REFNO,
                fromcompanyid: item.FROMCOMPANY,
                status: status,
                downloaded: isDownloaded,
                fromNotification: true
              }
            }
          );
        }
        break;
      }
      case "SALES":
        case "SALES INVOICE":
         {
           console.log("SALES INVOICE", item);
        //statements;
        if (
          parentNotificationId == 0 ||
          parentNotificationId == undefined ||
          parentNotificationId == null
        ) {
          //redirect to performa
          this.router.navigate(
            ["/pages/transaction/purchases/add-purchase-invoice"],
            {
              queryParams: {
                voucher: item.REFNO,
                fromcompanyid: item.FROMCOMPANY,
                status: status,
                downloaded: isDownloaded,
                fromNotification: true

              }
            }
          );
        }
        break;
      }
      case "PRE DEBIT NOTE": {
        //statements;
        if (
          parentNotificationId == 0 ||
          parentNotificationId == undefined ||
          parentNotificationId == null
        ) {
          //redirect to performa
          this.router.navigate(
            ["/pages/transaction/purchases/add-debitnote-itembase"],
            {
              queryParams: {
                voucher: item.REFNO,
                fromcompanyid: item.FROMCOMPANY,
                status: status,
                downloaded: isDownloaded,
                fromNotification: true

              }
            }
          );
        }
        break;
      }

      // case "SALES ORDER":
      //    {
      //      console.log("SALES ORDER", item);
      //   //statements;
      //   if (
      //     parentNotificationId == 0 ||
      //     parentNotificationId == undefined ||
      //     parentNotificationId == null
      //   ) {
      //     //redirect to performa
      //     this.router.navigate(
      //       ["/pages/transaction/sales/performa-invoice"],
      //       {
      //         queryParams: {
      //           voucher: item.REFNO,
      //           fromcompanyid: item.FROMCOMPANY,
      //           status: status,
      //           downloaded: isDownloaded,
      //           fromNotification: true

      //         }
      //       }
      //     );
      //   }
      //   break;
      // }
      case "PENDING SALES ORDER": {
        this.SalesOrderPendingList.show();
      }

      default: {
        //statements;
        console.log("no case found");
        break;
      }
    }
  }

  getData() {
    this.selectedRowIndex = 0;
    let apiUrl = `${this.apiUrl}${this.popupsettings.apiEndpoints
      }?currentPage=${this.pageNumber}&maxResultCount=${this.pageSize}`;
    apiUrl = this.getFilterOption(apiUrl);
    return this._http
      .get(apiUrl)
      .subscribe(res => {
        this.totalItems = res ? res['totalCount'] : 0;
        this.itemList = res ? res['data'] : [];
        // console.table(this.itemList);
      });
  }

  hide() {
    this.pageNumber = 1;
    this.totalItems = 0;
    this.isActive = false;
  }

  triggerSearch() {
    if (
      this.filterOption == null ||
      this.filterOption == undefined ||
      this.filterOption == ""
    )
      return;
    if (
      this.filterValue.value == null ||
      this.filterValue.value == undefined ||
      this.filterValue.value == ""
    )
      return;

    this.refreshPage();
    this.refresh();
  }

  singleClick(index) {
    this.selectedRowIndex = index;
  }

  doubleClick($event) {
    this.markAsRead($event);

  }

  // @HostListener("document : keydown", ["$event"])
  // updown($event: KeyboardEvent) {
  //   if (
  //     $event.code == "ArrowDown" &&
  //     this.selectedRowIndex < this.pageSize - 1
  //   ) {
  //     $event.preventDefault();
  //     this.selectedRowIndex++;
  //   }
  //   else if ($event.code == "ArrowDown") {
  //     $event.preventDefault();
  //     this.selectedRowIndex = 0;
  //     this.pageNumber = this.pageNumber + 1;
  //   }
  //   else if ($event.code == "ArrowUp" && this.selectedRowIndex - 1 > -1) {
  //     $event.preventDefault();
  //     this.selectedRowIndex--;
  //   }
  //   else if ($event.code == "ArrowUp") {
  //     $event.preventDefault();
  //     this.selectedRowIndex = 0;
  //     this.pageNumber = this.pageNumber > 0 ? this.pageNumber -1 : this.pageNumber
  //   }
  //    else if (
  //     $event.code == "Enter" &&
  //     this.selectedRowIndex >= 0 &&
  //     this.selectedRowIndex < this.itemList.length - 1
  //   ) {
  //     $event.preventDefault();

  //     let itemIndex = ((this.pageNumber - 1) * this.pageSize)  + (this.selectedRowIndex)
  //     this.onItemDoubleClick.emit(this.itemList[this.selectedRowIndex]);
  //     this.hide();
  //     this.itemListClosed();
  //   }
  // }

  itemListClosed() {
    this.onPopUpClose.emit();
  }


  toggle() {
    if (this.isActive) {
      this.hide();
    } else {
      console.log("toggle")
      this.getNotificationCount();
      this.refresh();
      this.show();
    }
  }
  
  onClickSalesOrder(){
    console.log("@@onClickSalesOrder")
  }
}
