<style>
    .selected-row {
     background-color: #ced6d3 !important;
   }
   .modal-sticky{
     right: 55px;
   }
 
   .unitLabel{
     width: auto;
     font-size: 14px;
     margin-left: 5px;
   }
 
 </style>
 
 <div class="modal-sticky" style="width: 50vw;">
 
   <div class="modal-content" style="padding: 2px !important;
   box-shadow: -10px 0px 10px 1px #aaaaaa;
   width: 101%;
   margin-left: 1pc;
   margin-top: 5pc;
   min-height: 350px;
   max-height: 350px;
   overflow: scroll;
   overflow-x: hidden;">
     <div class="modal-header" style="padding: 0px;    background: #7ed0ff;">
 
       <h5 class="modal-title">Item Wise Batch List</h5>
       <button class="close" aria-label="Close" (click)="itemBatchClosed()">
         <span aria-hidden="true">&times;</span>
       </button>
 
     </div>
   <div style="float: right;">
     <!-- <label>Search</label> -->
     <input type="search" [(ngModel)]="filter"   style="height: 1.5rem;font-size:13px;width: 755px;" placeholder="Search.."name="filter">
   </div>
     
     <table style="padding: 0px;margin-top: 2px;width: 100%;overflow: scroll" class="Table1">
        <tr style = "background: #7ed0ff">
            <th>Sn.</th>
            <th>BATCH</th>
            <th>STOCK</th>
            <th style="text-align: right;">P-Rate</th>
            <th style="text-align: right;" *ngIf="_trnMainService.TrnMainObj.VoucherType!=89">S-Price(Exc VAT)</th>
            <th style="text-align: right;" *ngIf="_trnMainService.TrnMainObj.VoucherType!=89">S-Price(Inc VAT)</th>

        </tr>
        <tbody>
            <tr aria-disabled="true" *ngFor="let BObject of (Blist|searchData:filter);let i=index" (click)="RowClickEvent(i)"
          (dblclick)="dblClickBatchObj(BObject)" [class.selected-row]="i === selectedRowIndex" class="highlight">
          <td style="border-bottom: 1px solid #ddd; height: 23px;width: 3% ;">{{i+1}}</td>
          <td  style="border-bottom: 1px solid #ddd;height: 23px;width:20%">{{BObject.BATCHCODE}}</td>
          <td style="border-bottom: 1px solid #ddd;height: 23px;width:20%">{{BObject.STOCK}}</td>
          <td style="border-bottom: 1px solid #ddd;height: 23px;width:15%;text-align: right;">{{BObject.LANDINGCOST| number:'1.2-2'}}</td>
          <td style="border-bottom: 1px solid #ddd;height: 23px;width:15%;text-align: right;" *ngIf="_trnMainService.TrnMainObj.VoucherType!=89">{{BObject.SELLRATEBEFORETAX| number:'1.2-2'}}</td>
          <td style="border-bottom: 1px solid #ddd;height: 23px;width:15%;text-align: right;" *ngIf="_trnMainService.TrnMainObj.VoucherType!=89">{{BObject.MRP| number:'1.2-2'}}</td>
          </tr>
          
        </tbody>
    </table>
    
     
    
   </div>
 </div>