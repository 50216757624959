import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IMSINPUTREADONLY } from '../directives/ims-input-readonly.directive';
import { IMSGridModule } from '../popup/ims-grid/ims-grid.module';
import { GenericPopupGridModule } from '../popup/generic-grid/generic-popup-grid.module';
import { OnlyNumber } from '../directives/onlynumber.directive';
import { TwoDigitNumber } from '../directives/twoDigitNumber.pipe';
import { FilterPipe, SortByPipe, KeysPipe } from '../pipe/pipes';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxPaginationModule } from 'ngx-pagination';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IMSGridModule.forRoot(),
        GenericPopupGridModule.forRoot(),
        NgxDaterangepickerMd,
        NgxPaginationModule,
        PerfectScrollbarModule
    ],
    declarations: [
        IMSINPUTREADONLY,
        OnlyNumber,
        TwoDigitNumber,
        FilterPipe,
        SortByPipe,
        KeysPipe,
        OnlyNumber,
        
    ],
    providers: [
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IMSINPUTREADONLY,
        IMSGridModule,
        GenericPopupGridModule,
        OnlyNumber,
        TwoDigitNumber,
        FilterPipe,
        NgxDaterangepickerMd,
        SortByPipe,
        KeysPipe,
        OnlyNumber,
        NgxPaginationModule,
        PerfectScrollbarModule
    ]
})
export class SharedModule {
}
