<div class="page-top clearfix" baScrollPosition maxHeight="50" (scrollChange)="scrolledChanged($event)"
  [ngClass]="{ scrolled: isScrolled }">
  <div class="menu-area master-menu">
    <div class="dropdown" *ngFor="let menuItem of menuItems">
     <!-- <ng-container *ngIf="EnableOfflineMenu(menuItem)"> -->
        <!-- <a *ngIf="!menuItem.children && menuItem.url && isShowPageMenu(menuItem)"
          (mouseenter)="onHoverItem($event, item)" [href]="menuItem.url" [target]="menuItem.target"
          class="main-menu-button">

          <i *ngIf="menuItem.icon" class="{{ menuItem.icon }}"></i><span>{{ menuItem.title }}</span>
        </a> -->
        <!-- <a *ngIf="!menuItem.children && !menuItem.url && isShowPageMenu(menuItem)"
          (mouseenter)="onHoverItem($event, item)" [ngClass]="{ selected: menuItem.title == selectedMenuItems.title }"
          class="main-menu-button" [routerLink]="menuItem.route.paths" [title]="menuItem.title">
          <i *ngIf="menuItem.icon" class="{{ menuItem.icon }}"></i><span>{{ menuItem.title }}</span>
        </a> -->

        <!-- added by roshan  ng-container here and if condition -->


      <ng-container *ngIf="EnableOfflineMenu(menuItem)">
        <a *ngIf="menuItem.children && isShowPageMenu(menuItem)" (click)="setSelectedMenu(menuItem)"
          [ngClass]="{ selected: menuItem.title == selectedMenuItems.title }" class="main-menu-button"
          [title]="menuItem.title">

          <i *ngIf="menuItem.icon && menuItem.title !='IMSPOS'" class="{{ menuItem.icon }}"></i>
          <span *ngIf="menuItem.title !='IMSPOS'">{{ menuItem.title }}</span>
          <div style="float: left;">
            <img style="position: relative;
          top: 5px;
          height: inherit;
          width: 60px;" *ngIf="menuItem.title =='IMSPOS'" src="{{ icon }}" (click)="logoClickEvent()">
          </div>

        </a>
      </ng-container>
    </div>

  </div>

  <div style="float: right;margin-top: 5px">
    <a (click)="onHelpclicked()"style="font-size: 14px;"><span style="font-weight: 600;margin-right: 7px;">Help</span></a>
  </div>
  <div class="main-menu-inner menu-area master-menu" style="float: right;margin-left:0px;margin-top: 5px">
    <div class="dropdown">
      <a class="dropbtn">

        <i class=  "fa fa-cog" style="font-size: 20px;color:#616363">
        </i><i class="ion-arrow-down-b" style="color: #616363"></i>
      </a>
      <div class="dropdown-content" style="min-width: 136px !important;right: -17px;;">
        <a title="log out" class="signout" (click)="logout()" style="cursor:pointer;padding-left: 10px;">
          <i class="fa fa-power-off" aria-hidden="true"></i><span>Sign Out</span>
        </a>
        <a title="change password" class="signout" (click)="changePasswordPrompt()"
          style="padding-left: 10px;cursor:pointer;">
          <i class="fa fa-key"></i><span>Change Password</span>
        </a>
        <a title="backup" class="signout" (click)="TakeBackup()"
        style="padding-left: 10px;cursor:pointer;">
        <i class="fa fa-database"></i><span>Backup</span>
      </a>
        <a title="change password" class="signout" (click)="dayClose()"
        style="padding-left: 10px;cursor:pointer;">
        <i class="fa fa-lock"></i><span>Day Close</span>
      </a>
        <a href="./../../../assets/help.pdf" target="_blank" style="padding-left: 10px;">
          <i class="fa fa-question-circle" aria-hidden="true"></i><span>Help</span>
        </a>

      </div>
    </div>
  </div>
  
  
  <div class="main-menu-inner menu-area master-menu" style="float: right;margin-left: 10px;margin-top: 5px">
    <a (click)="downloadPrintService()" title="configure printer" style="cursor: pointer">
      <i class="fa fa-print" aria-hidden="true" style="font-size: 20px;color:#616363"></i>
    </a>
  </div>
  <div  style="float: right;margin-left: 10px;margin-top: 5px">
    <a (click)="onDivisionClicked()"style="font-size: 14px;margin-right:8px;"><span style="font-weight: 600;margin-right: 7px;">Div :</span>{{divisionName}}</a>
    <a (click)="onPhiscalYearClicked()" style="font-size: 14px;margin-right:8px;"><span style="font-weight: 600;margin-right: 7px;">@ F/Y :</span>{{phiscalYearInfo}}</a>
    <a (click)="toggleNotificationPopup()" class="notification notification-sticky" [class.notification-active]= "isActive">
      <span><i class="fa fa-bell" aria-hidden="true" id="notification"></i></span>
      <span style="color: red;">{{notificationCount}}</span>
      </a>
  </div>

 

</div>
<div class="main-menu clearfix">
  <div class="main-menu-inner">
    <div class="dropdown" *ngFor="let menuItem of selectedMenuItems.children">
      <ng-container *ngIf="EnableOfflineMenuChild(menuItem)">
        <a *ngIf="!menuItem.children && isShowPageMenu(menuItem)" [ngClass]="{ 'dropbtn-selected': menuItem.selected }"
          class="dropbtn" [routerLink]="menuItem.route.paths" [title]="menuItem.title">
          <i *ngIf="menuItem.icon" class="{{ menuItem.icon }}"></i>
          <span>{{ menuItem.title }}</span>
        </a>
        <a *ngIf="menuItem.children && isShowPageMenu(menuItem)" class="dropbtn" [title]="menuItem.title">
          <i *ngIf="menuItem.icon" class="{{ menuItem.icon }}"></i>
          <span>{{ menuItem.title }}</span> <i class="ion-arrow-down-b"></i>
        </a>
        <div class="dropdown-content" *ngIf="menuItem.children && isShowPageMenu(menuItem)">
          <ng-container *ngFor="let childrenItem of menuItem.children">
            <ng-container *ngIf="EnbaleOfflineChildren(childrenItem)">
              <a [ngClass]="{ 'dropbtn-selected': menuItem.selected }" [routerLink]="childrenItem.route.paths"
                *ngIf="!childrenItem.children && isShowPageMenu(childrenItem)">
                <i *ngIf="childrenItem.icon" class="{{ childrenItem.icon }}"></i>
                <span>{{ childrenItem.title }}</span>
              </a>

              <!-- more level down to menu -->
              <div class="dropdown-inner">
                <a *ngIf="childrenItem.children && isShowPageMenu(childrenItem)" class="dropbtn-inner"
                  [title]="childrenItem.title">
                  <i *ngIf="childrenItem.icon" class="{{ childrenItem.icon }}"></i>
                  <span>{{ childrenItem.title }}</span>
                  <i class="ion-arrow-right-b" style="float:right"></i>
                </a>
                <div class="dropdown-content-inner" *ngIf="childrenItem.children && isShowPageMenu(childrenItem)">
                  <ng-container *ngFor="let childrenChildrenItem of childrenItem.children">
                    <ng-container *ngIf="EnbaleChildOfChildren(childrenChildrenItem)">
                      <a [ngClass]="{ 'dropbtn-selected': menuItem.selected }"
                      [routerLink]="childrenChildrenItem.route.paths" *ngIf="isShowPageMenu(childrenChildrenItem)">
                      <i *ngIf="childrenChildrenItem.icon" class="{{ childrenChildrenItem.icon }}"></i>
                      <span>{{ childrenChildrenItem.title }}</span>
                    </a>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>

    </div>
  </div>
</div>

<notification-popup-grid #notification 
(notificationCountUpdate)="updateNotificationCount($event)"
>
</notification-popup-grid>



<div class="print-modal-sticky" *ngIf="forgetPassword" style="    display: block;
padding: 0px;
position: absolute;
right: 42%;
top: 25%;
min-height: 504px;
width: 20%;
z-index: 9999999;
">

  <div class="modal-content modal-content-main-area">
    <div class="modal-header">
      <h4 class="modal-title" id="myModalLabel">Change Password</h4>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
          <label for="username">User Name</label>
          <input type="text" class="form-control" name="username" [(ngModel)]="userData.username">
        </div>
        <div class="form-group">
          <label for="Old_Password">Old Password</label>
          <input type="password" class="form-control" name="Old_Password" [(ngModel)]="userData.password">
        </div>
        <div class="form-group">
          <label for="new_Password">New Password</label>
          <input type="password" class="form-control" name="new_Password" [(ngModel)]="userData.newPassword">
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-info" data-dismiss="modal" (click)="changePassword()">
        <span *ngIf="!changepwd">Change Password</span>
        <span *ngIf="changepwd">Changing Password
          <i class="fa fa-spinner fa-pulse  fa-fw"></i>
        </span>
      </button>
      <button type="button" class="btn btn-info" (click)="cancelPassword()">Cancel</button>
    </div>
  </div>

</div>

<app-helpdescription-form #CreateHelpParty></app-helpdescription-form>
<!-- ******************************************HELPLAYOUT********************************************* -->
<!-- <style>
  td {
    border: 1px solid #e6e6e6;
  }

  input {
    padding: 8px;
  }

  .form-group {
    margin-bottom: 0rem;
  }

  .party_container {
    position: fixed !important;
    transform: translate(0px, -35px);
    background: #ffffff;
    z-index: 99999;
    width: 77%;
    top: 22.5%;
    left: 10%;
  }
 
</style> -->


<!-- <div *ngIf="isHelpActive" class="party_container" role="document" 
  style="max-width: 100%; max-height: 500px; overflow:hidden">
  <div class="modal-content" style="padding: 14px 16px 10px 16px;margin: 0;width: 77vw; height:70vh;">

    <div class="modal-header row">
      <div class="col-md-7">
        <ul class="nav nav-tabs " style="background-color: #e9e9e9 !important;height: 33px; width: 360px;">

          <li class="nav-item " style="padding-top: 5px;padding-left: 10px;">
            <a class="nav-link"  data-toggle="tab"  href="#Description" style="color:black">Description</a>
          </li>
          <li class="nav-item" style="padding-top: 5px;padding-left: 10px;">
            <a  class="nav-link"  data-toggle="tab"  href="#LabelInputs" style="color:black">Label and Inputs</a>
          </li>
          <li class="nav-item" style="padding-top: 5px;padding-left: 10px;">
            <a class="nav-link"  data-toggle="tab"  href="#KeysFeatures" style="color:black">Keys & Features</a>
          </li>
        </ul>
     
      </div>
      <div class="col-md-5"> 
        <button type="button" class="btn btn-info pull-right" style="width:80px;"
          (click)="cancelHelpPopup()">Cancel</button>
    
      </div>
    </div>
    <div class="tab-content">
      <div class="tab-pane active" id="Description">      
          <div class="col-md-12 row" style="width: 100%;">
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
               Quibusdam quam obcaecati rerum perferendis suscipit ullam enim illum ipsum commodi, 
              ut id, nulla voluptatem amet saepe ab iusto, aliquid est unde.
            </p>
          </div>
        
      </div>
      <div class="tab-pane" id="LabelInputs">
        <div class="col-md-12 row">
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
             Quibusdam quam obcaecati rerum perferendis suscipit ullam enim illum ipsum commodi, 
            ut id, nulla voluptatem amet saepe ab iusto, aliquid est unde.
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, iure explicabo esse quo repudiandae, 
            aperiam culpa voluptate neque quae doloremque labore odio molestiae 
            tempore nemo perspiciatis commodi mollitia facilis earum!
          </p>
  
        </div>
      </div>
      <div class="tab-pane" id="KeysFeatures">
        <div class="col-md-12 row">
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
             Quibusdam quam obcaecati rerum perferendis suscipit ullam enim illum ipsum commodi, 
            ut id, nulla voluptatem amet saepe ab iusto, aliquid est unde.
          </p>
        </div>
      </div>
    
    </div>
</div>

</div> -->
