import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { FormBuilder } from '@angular/forms';
import { MasterRepo } from '@app/_services/masterRepo.service';
import { TransactionService } from '@app/common/Transaction Components/transaction.service';
import { AlertService } from '@app/common/shared/alert/alert.service';
import { SpinnerService } from '@app/common/shared/spinner/spinner.service';
import { Router } from '@angular/router';

@Component({
  selector: 'salesorder-pending-dialog',
  templateUrl: './salesorder-pending.component.html',
  styleUrls: ['./salesorder-pending.component.css'],
})
export class SalesOrderPendingComponent implements OnInit {
  isActive: boolean = false;

  vchrno: string;
  remarks: string;
  @Output() onClickSalesOrder: EventEmitter<any> = new EventEmitter<any>();
  StockOrderList: any[];

  constructor(
    public fb: FormBuilder, public masterService: MasterRepo,
    public _trnMainService: TransactionService,
    private alertService: AlertService,
    public spinnerService: SpinnerService,public router: Router
  ) { }

  ngOnInit() { }

  doubleClickSOList() {
    this.onClickSalesOrder.emit();
  }

  closeDialog() {
    this.isActive = false;
  }

  show() {
    this.isActive = true;
    this.masterService.getRemainingSOStockList("").subscribe((data: any) => {
      console.log("@@data1", data)
      if (data.status == "ok") {
        if (data.result.length > 0) {
          this.StockOrderList = data.result;
        }
      }
    })
  }

  hide() {
    this.isActive = false;
  }

  DeleteSOFromList(value){
    this.masterService.deleteSOstockFromRemStockList(value.MCODE).subscribe(res=>{
      console.log("@@res",res)
      if(res['status'] == "ok"){
        this.masterService.getRemainingSOStockList("").subscribe((data: any) => {
          if (data.status == "ok") {
            if (data.result.length > 0) {
              this.StockOrderList = data.result;
            }
          }
        })
      }
    })
  }

  routeToPerforma(value){
    this.isActive = false;
    console.log("@@routeToPerforma",value)
    let qty=0;
    if(value.STOCK>=value.REMAINING_ORDER_STOCK){ //10>=4
      qty=value.REMAINING_ORDER_STOCK;
    }else{  //3<4
      qty=value.STOCK;
    }
    this.router.navigate(
      ['/pages/transaction/sales/performa-invoice'],
      {
      queryParams: {
        ponumber: value.VCHRNO,
        division:value.DIVISION,
        PhiscalId:value.PHISCALID,
        Refbill:"SOINTI",
        mode:"fromSOpopup",
        mcode:value.MCODE,
        qty:qty
      }
      }
    );
  }

}
