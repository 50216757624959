<h3 matDialogTitle>Expired Proforma Reconcilling...</h3>
<div >
    <table style="border: none;">
        <tbody>
      <tr *ngFor="let datas of reconcilling;let i=index" >
        <!-- <td>{{datas.VCHRNO}} is reconcilling</td> -->
        <td>Voucher no. {{datas.VCHRNO}} is reconcilling</td>
        <td style="padding-left:80px;"> <span class="spinner-border spinner-border-sm mr-1" *ngIf="done(i)" ></span></td>
        <td><input type="checkbox" style="height: 20px;width:30px" [(ngModel)]="datas.checked" (change)="checks(i)"></td>
    </tr>
    </tbody>
 </table>
 <button class="btn btn-primary btn-md"style="float: right;margin-top:10px;margin-bottom:0%" (click)="close()" [disabled]="allowButton">OK</button>
</div>