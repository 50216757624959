<div class="customer_container" *ngIf="isActive">
  <div class="card">
    <div class="card-header">
      <h5 class="page-title">Pending Sales Order List</h5>
      <div class="button-group">
        <!-- <button title="Save" type="submit" class="btn btn-info" (keydown.F6)="submit()" (click)="submit()" 
              style="margin-right:2px">SAVE</button> -->

        <button type="button" title="Cancel" class="btn btn-info" (click)="closeDialog()">CANCEL</button>
      </div>
    </div>
    <div class="card-body">


      <div class="col-md-12 row" style="width: 100%;">
        <div style="overflow-y: auto; width: 100%; height:300px">
          <table style="margin-bottom: 5px; font-size: 13px; ">
            <thead>
              <tr style="
                    border-bottom: 2px solid #e6e6e6;
                    border-top: 2px solid #e6e6e6;
                    height: 30px;
                    background: #17a2b8;
                    color: black;">
                <th style="width: 1%; text-align:center;">SNo.</th>
                <th style="width: 25%; padding-left: 1%; text-align:center;">Voucher No</th>
                <th style="width: 25%; padding-left: 1%; text-align:center;">Mcode</th>
                <th style="width: 25%; padding-left: 1% ;text-align:center;">Order Qty</th>
                <th style="width: 25%; padding-left: 1%; text-align:center;">Stock</th>
                <th style="width: 25%; padding-left: 1%; text-align:center;">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of StockOrderList let i = index" style="border: 1px; max-height: 25px">
                <td style="line-height: 21px; text-align: left; border: 1px solid #e6e6e6;">
                  {{i+1}}
                </td>
                <td style="line-height: 21px; text-align: left; border: 1px solid #e6e6e6;" (dblclick)="routeToPerforma(data)">
                  {{data.VCHRNO}}
                </td>
                <td style="line-height: 21px; text-align: left; border: 1px solid #e6e6e6;" (dblclick)="routeToPerforma(data)">
                  {{data.MCODE}}
                </td>
                <td style="line-height: 21px; text-align: left; border: 1px solid #e6e6e6;" (dblclick)="routeToPerforma(data)">
                  {{data.REMAINING_ORDER_STOCK}}
                </td>
                <td style="line-height: 21px; text-align: left; border: 1px solid #e6e6e6;" (dblclick)="routeToPerforma(data)">
                  {{data.STOCK}}
                </td>
                <td style="line-height: 21px; text-align: left; border: 1px solid #e6e6e6;">
                  <button class="btn btn-info" (click)="DeleteSOFromList(data)">Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>