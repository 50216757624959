<div class="abWhiteAndBlack3">
    <div class="container">
        <div class="row">
            <div class="col-md-offset-5 col-md-3">
                <div class="form-login">
                    <img class="img-responsive" src="assets/Marg.jpeg">
                <h4>Welcome to DMS</h4>
                <h6>Powered By IMS Software</h6>
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <!-- <input type="text" id="userName" class="form-control input-sm chat-input"  /> -->
                <input autocomplete="false" type="text" formControlName="username" placeholder="username" class="form-control input-sm chat-input" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required">Username is required</div>
                </div>
                <br>
                <!-- <input type="text" id="userPassword" class="form-control input-sm chat-input" placeholder="password" /> -->
                <input autocomplete="false" type="password" formControlName="password" class="form-control input-sm chat-input" placeholder="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                </div>
                <br>
                <div class="wrapper">
                <span class="group-btn">     
                    <button [disabled]="loading" class="btn btn-primary btn-md">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1" ></span>
                        Sign In
                    </button>
                    

                </span>

               
                </div>
                </form>
                </div>
            
            </div>
        </div>
    </div>
</div>

<ng-template #callAPIDialog >
    <h3 matDialogTitle>Expired Performa Reconcilling...</h3>
<div >
    <table style="border: none;">
        <tbody>
      <tr *ngFor="let datas of reconcilling" >
        <td>{{datas.VCHRNO}} is Auto reconcilling</td>
        <td style="padding-left:20px;"> <span class="spinner-border spinner-border-sm mr-1" *ngIf="checked==false" ></span></td>
        <td><input type="checkbox" style="height: 20px;width:30px" (click)="checks($event)"></td>
    </tr>
    </tbody>
 </table>
 <button class="btn btn-primary btn-md"style="float: right;margin-top:10px;margin-bottom:0%" (click)="close()">OK</button>
</div>
</ng-template>