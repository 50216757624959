import { Component, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { TransactionService } from "@app/common/Transaction Components/transaction.service";
import { MasterRepo } from "@app/_services/masterRepo.service";

@Component(
    {
        selector: 'freeListPopUp',
        templateUrl: './PopupFree.component.html',
    }
)

export class FreeListPopup {
    @Input()Flist: any[] = [];
    @Output() FreeClosedEmit = new EventEmitter();
    // @Output() returnBatchValue = new EventEmitter();
    @Output() dbClickEvent = new EventEmitter();
    showPopup: boolean = false;
    selectedRowIndex: number;
    filter:any;
    dlclicked:boolean=false

    constructor(
        private masterRepo: MasterRepo,
      public _trnMainService: TransactionService
    ){

    }

    ngOnInit(){
        console.log("freestock popup shown",this.Flist);
    }

    RowClickEvent(index) {
        this.selectedRowIndex = index;
    }
    dblClickFreeObj(value) {
        this.dlclicked=true;
        this.dbClickEvent.emit(value)
    }

    

    freeStockClosed(){
        if(this.dlclicked==true){
            this.FreeClosedEmit.emit()
        }
       
    }

    @HostListener("document : keydown", ["$event"])
    updown($event: KeyboardEvent) {
        if (this.selectedRowIndex == null) this.selectedRowIndex = 0;

        if ($event.code === "ArrowDown") {
            this.selectedRowIndex = this.selectedRowIndex + 1;
            if (this.selectedRowIndex > (this.Flist.length - 1)) this.selectedRowIndex = this.Flist.length - 1;
        }
        else if ($event.code === "ArrowUp") {
            this.selectedRowIndex = this.selectedRowIndex - 1;
            if (this.selectedRowIndex < 0) this.selectedRowIndex = 0;
        }
        if ($event.code === "Enter" || $event.code == "NumpadEnter") {
            if (this.selectedRowIndex != null) {
                if (this.Flist[this.selectedRowIndex] != null) {
                    this.dblClickFreeObj(this.Flist[this.selectedRowIndex]);
                }
            }
        } else if ($event.code === "Escape") {
            this.freeStockClosed();
        }


    }
}
