<style>
    .selected-row {
     background-color: #ced6d3 !important;
   }
   .modal-sticky{
     right: 55px;
   }
 
   .unitLabel{
     width: auto;
     font-size: 14px;
     margin-left: 5px;
   }
 
 </style>
 
 <div class="modal-sticky" style="width: 50vw;">
 
   <div class="modal-content" style="padding: 2px !important;
   box-shadow: -10px 0px 10px 1px #aaaaaa;
   width: 106%;
   margin-left: 1pc;
   margin-top: 5pc;
   min-height: 350px;
   max-height: 350px;
   overflow: scroll;
   overflow-x: hidden;">
     <div class="modal-header" style="padding: 0px;    background: #7ed0ff;">
 
       <h5 class="modal-title">Stock/Primary Discount List</h5>
       <button class="close" aria-label="Close" (click)="freeStockClosed()">
         <span aria-hidden="true">&times;</span>
       </button>
 
     </div>
   <!-- <div style="float: right;"> -->
     <!-- <label>Search</label> -->
     <!-- <input type="search" [(ngModel)]="filter"   style="height: 1.5rem;font-size:13px;width: 755px;" placeholder="Search.."name="filter">
   </div> -->
     
     <table style="padding: 0px;margin-top: 2px;width: 100%;overflow: scroll" class="Table1">
        <tr style = "background: #7ed0ff">
            <th style="width: 3%;font-size: 13px;">Sn.</th>
            <th style="width:10%;font-size: 13px;">MCODE</th>
            <th style="width:25%;margin-left: 5px;font-size: 13px;">PRODUCT NAME</th>
            <th style="width:8%;font-size: 13px;">BATCH</th>
            <th style="width:12%;font-size: 13px;margin-left: 5px">FREE-STOCK</th>
            <th style="margin-left: 5px;width:5%;font-size: 13px;">S.Dis</th>
            <th style="width:9%;font-size: 13px;">S.DisRate</th>
            <!-- <th style="width:12%;font-size: 13px;">MfgDate</th> -->
            <th style="width:21%;font-size: 13px;">ExpDate</th>
           

        </tr>
        <tbody>
            <!-- <tr aria-disabled="true" *ngFor="let FObject of (Flist|searchData:filter);let i=index" (click)="RowClickEvent(i)"
          (dblclick)="dblClickBatchObj(BObject)" [class.selected-row]="i === selectedRowIndex" class="highlight"> -->
          <tr aria-disabled="true" *ngFor="let FObject of Flist;let i=index" (click)="RowClickEvent(i)"
          (dblclick)="dblClickFreeObj(FObject)" [class.selected-row]="i === selectedRowIndex" class="highlight">
          <td style="border-bottom: 1px solid #ddd; height: 23px;width: 3% ;font-size: 14px;">{{i+1}}</td>
          <td  style="border-bottom: 1px solid #ddd;height: 23px;width:10%;font-size: 14px;">{{FObject.mcode}}</td>
          <td style="border-bottom: 1px solid #ddd;height: 23px;width:28%;margin-left: 5px;font-size: 14px;">{{FObject.productname}}</td>
          <td style="border-bottom: 1px solid #ddd;height: 23px;width:5%;font-size: 14px;">{{FObject.batch}}</td>
          <td style="border-bottom: 1px solid #ddd;height: 23px;width:12%;text-align: center;font-size: 14px;">{{FObject.freestock}}</td>
          <td style="border-bottom: 1px solid #ddd;height: 23px;width:10%;font-size: 14px;">{{FObject.freeamnt}}</td>
          <td style="border-bottom: 1px solid #ddd;height: 23px;width:9%;font-size: 14px;">{{FObject.freerate |twoDigitNumber}}</td>
          <!-- <td style="border-bottom: 1px solid #ddd;height: 23px;width:10%;font-size: 14px;">2078-01-20</td> -->
          <td style="border-bottom: 1px solid #ddd;height: 23px;width:21%;font-size: 14px;">{{FObject.expdate| date: 'dd-MM-yyyy'}}</td>
          </tr>
          
        </tbody>
    </table>
    
     
    
   </div>
 </div>