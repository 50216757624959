import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxPaginationModule } from 'ngx-pagination';
import { NotificationPopUpComponent } from './notification-popup-grid.component';
import { MasterRepo } from '@app/_services/masterRepo.service';
import { NotificationService } from './notification.service';
import { SalesOrderPendingModule } from "../salesorder-pending-popup/salesorder-pending.module";
import { ReverseEntryModule } from "../reverse-entry-popup/reverse-entry.module";


@NgModule({
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    NgxPaginationModule,
    ReverseEntryModule.forRoot(),
    SalesOrderPendingModule.forRoot()
  ],
  declarations: [NotificationPopUpComponent],
  exports: [NotificationPopUpComponent]
})
export class NotificationPopupGridModule {
  static forRoot(): ModuleWithProviders<NotificationPopupGridModule> {
    return {
      ngModule: NotificationPopupGridModule,
      providers: [MasterRepo, NotificationService]
    };
  }
}
