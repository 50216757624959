import { Component, OnInit, Inject, HostListener, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit} from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MasterRepo } from '@app/_services/masterRepo.service';
import { TransactionService } from '@app/common/Transaction Components/transaction.service';
import { AlertService } from '@app/common/shared/alert/alert.service';
import { SpinnerService } from '@app/common/shared/spinner/spinner.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'reverse-entry-dialog',
  templateUrl: './reverse-entry.component.html',
  styleUrls: ['./reverse-entry.component.css'],
})
export class ReverseEntryComponent implements OnInit {
  isActive:boolean = false;

  vchrno:string;
  remarks:string;
  @Output() onClickReverse: EventEmitter<any> = new EventEmitter<any>();


  constructor(
    public fb: FormBuilder, public masterservice: MasterRepo,
    public _trnMainService: TransactionService, 
    private alertService: AlertService,
    public spinnerService:SpinnerService
  ) {
   
    
  }

  ngOnInit() {
   }

   submit(){
   
    if(this._trnMainService.TrnMainObj.AdditionalObj.CREFBILL.trim() == null || this._trnMainService.TrnMainObj.AdditionalObj.CREFBILL.trim()  == "" || this._trnMainService.TrnMainObj.AdditionalObj.CREFBILL.trim()  == undefined ){
      this.alertService.warning("Enter Voucher no.");
      return;
    }
    if( this._trnMainService.TrnMainObj.AdditionalObj.CREMARKS.trim()== null || this._trnMainService.TrnMainObj.AdditionalObj.CREMARKS== "" || this._trnMainService.TrnMainObj.AdditionalObj.CREMARKS== undefined){
      this.alertService.warning("Enter reverse entry confirmation remarks");
      return;
    }

    if(this._trnMainService.TrnMainObj.AdditionalObj.CREFBILL.trim()  != this._trnMainService.TrnMainObj.VCHRNO){
      this.alertService.warning(`${this._trnMainService.TrnMainObj.VoucherAbbName} no should match with the loaded Voucher No.`);
      return;
    }
    this._trnMainService.TrnMainObj.REFBILL = this._trnMainService.TrnMainObj.AdditionalObj.CREFBILL.trim();
    this.onClickReverse.emit();    
   }


  closeDialog() {
    this.isActive=false;
  }

//   keyPress(event: any) {
//     const pattern = /[0-9]/;
//     const inputChar = String.fromCharCode((event as KeyboardEvent).charCode);
//     if (!pattern.test(inputChar)) {
//       // invalid character, prevent input
//       event.preventDefault();
//     }
//   }

  show(billNo:string) {
      console.log("SHOWNN",billNo);
      this.vchrno = billNo;
    this.isActive = true;

  }



hide() {
    this.isActive = false;
}

onclear(){

}

//   @HostListener("document : keydown", ["$event"])
//   handleKeyDownboardEvent($event: KeyboardEvent) {
//     if ($event.code == "Escape") {
//       $event.preventDefault();
//         this.closeDialog();
//     }
//     if ($event.code == "End") {
//       if(!this.form.invalid){
//         $event.preventDefault();
//         this.submit(this.form.value);
//       }
      
//     }
//   }
}
