import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TrnMain, VoucherTypeEnum } from "@app/common/interfaces/TrnMain";
import { SpinnerService } from "@app/common/shared/spinner/spinner.service";
import { TransactionService } from "@app/common/Transaction Components/transaction.service";
import { AuthenticationService } from "@app/_services";
import { MasterRepo } from "@app/_services/masterRepo.service";
import * as uuid from "uuid";


@Component({
  selector: "app-reconcile-popup",
  templateUrl: "./reconcile-popup.component.html",
  styleUrls: ["./reconcile-popup.component.css"],
})
export class ReconcilePopupComponent implements OnInit {
  checked: boolean ;
  public reconcilling: any[] = [];
  mode = "NEW";
lengths:any;
allowButton=true;
  constructor(
    public trnMainService: TransactionService,
    public matDialog: MatDialog,
    public authenticationService: AuthenticationService,
    private masterService: MasterRepo,

  ) {
    setTimeout(() =>{  
      this.allowButton = false;  
    },11000); 
  }

  ngOnInit(): void {
   let index:number=0 
    this.authenticationService.getTodayExpirePerforma().subscribe((data: any) => {
        this.reconcilling = data.result;
      this.lengths=this.reconcilling.length;
      this.saveSerially(this.lengths,index)
    }, err=>{
      
    })
  }
  saveSerially(reconcileLength,index){
    if(index < reconcileLength){
      this.trnMainService.TrnMainObj.VCHRNO =  this.reconcilling[index].VCHRNO;
            this.trnMainService.TrnMainObj.REFBILL= this.reconcilling[index].VCHRNO;
            this.trnMainService.TrnMainObj.TRNAC=this.reconcilling[index].TRNAC;
            this.trnMainService.TrnMainObj.PARAC=this.reconcilling[index].PARAC;
            this.trnMainService.TrnMainObj.TRNMODE=this.reconcilling[index].TRNMODE;
          this.trnMainService.TrnMainObj.MWAREHOUSE=this.reconcilling[index].MWAREHOUSE;
          this.trnMainService.TrnMainObj.INVOICETYPE=this.reconcilling[index].INVOICETYPE;
          this.trnMainService.TrnMainObj.SALESMAN=this.reconcilling[index].SALESMAN;
          this.trnMainService.TrnMainObj.SALESMANID=this.reconcilling[index].SALESMANID;
          this.trnMainService.TrnMainObj.VoucherPrefix = "PQ";
          this.trnMainService.TrnMainObj.BILLTO = "Performa Warehouse"; //from
          this.trnMainService.TrnMainObj.BILLTOADD = "Main Warehouse"; //to
          this.trnMainService.TrnMainObj.PhiscalID = "78/79";
          this.trnMainService.TrnMainObj.PCL = "PC001";
          this.trnMainService.TrnMainObj.DIVISION = "MMX";
          this.trnMainService.TrnMainObj.Mode="NEW"
          this.trnMainService.TrnMainObj.VoucherType=VoucherTypeEnum.CancelProforma;
          this.trnMainService.TrnMainObj.ProdList=[];
          this.trnMainService.TrnMainObj.REMARKS="Automatically system reconcilled";
          this.getCurrentDate();
          this.trnMainService.TrnMainObj.guid = uuid();
            this.masterService .saveTransaction(this.mode, this.trnMainService.TrnMainObj)
            .subscribe((data:any) => {
              if(data.status=="ok"){
                this.reconcilling[index].checked=true;
                index ++;
                this.saveSerially(this.lengths,index)
              }
            });
          }
          else{
            return;
          }
          console.log(this.trnMainService.TrnMainObj);
  }

  checks( index) {
   console.log(this.reconcilling[index].checked)
  }
  done(i){
    if(this.reconcilling[i].checked ==true){
      return false;
    }
    return true;
     }
  close() {
    this.matDialog.closeAll();
  }
 
  getCurrentDate() {
    this.masterService.getCurrentDate().subscribe((date) => {
      this.trnMainService.TrnMainObj.TRNDATE = date.Date.substring(0, 10);
      this.trnMainService.TrnMainObj.TRN_DATE = date.Date.substring(0, 10);
      this.trnMainService.TrnMainObj.BSDATE = this.trnMainService.toBSDate(
        this.trnMainService.TrnMainObj.TRNDATE.toString()
      );
      this.trnMainService.TrnMainObj.BS_DATE =this.trnMainService.toBSDate(
        this.trnMainService.TrnMainObj.TRN_DATE.toString()
      );
    });
  }

  getBsDate(date) {
    let adbs = require("ad-bs-converter");
    console.log("CHANGE TO BS YOYOYO", date);
    let adDate = date.replace("-", "/").replace("-", "/");
    let bsDate = adbs.ad2bs(adDate);
    return (
      (bsDate.en.day == "1" ||
      bsDate.en.day == "2" ||
      bsDate.en.day == "3" ||
      bsDate.en.day == "4" ||
      bsDate.en.day == "5" ||
      bsDate.en.day == "6" ||
      bsDate.en.day == "7" ||
      bsDate.en.day == "8" ||
      bsDate.en.day == "9"
        ? "0" + bsDate.en.day
        : bsDate.en.day) +
      "/" +
      (bsDate.en.month == "1" ||
      bsDate.en.month == "2" ||
      bsDate.en.month == "3" ||
      bsDate.en.month == "4" ||
      bsDate.en.month == "5" ||
      bsDate.en.month == "6" ||
      bsDate.en.month == "7" ||
      bsDate.en.month == "8" ||
      bsDate.en.month == "9"
        ? "0" + bsDate.en.month
        : bsDate.en.month) +
      "/" +
      bsDate.en.year
    );
  }


}

export interface Reconcile {
  DIVISION: string;
  PHISCALID: string;
  TRNDATE: string;
  VCHRNO: string;
}
