<style>
  .modal-content {
    padding: 1px;
    margin: 0px;
    float: right;
    width:50%;
    z-index: 1;
    position: absolute;
    left:50%;
  }
  .table-scroll {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  tbody > tr:hover {
    background-color: #f3f5f4;
    cursor: pointer;
  }
  tr {
    font-size: 11px;
  }
  .selected-row {
    background-color: #ced6d3 !important;
  }

  ul.table {
    padding: 0px;
    max-height : 450px;
    overflow-y: scroll;
  }

  li.highlight.item {
    text-decoration: none;
    list-style: none;
    padding: 0px;
    margin: 5px 0px;
    cursor: pointer;
  } 
   
  p.date {
    font-size: 9px;
    color: #495057;
    float: right; 
    margin-top: -28px;
}

  .message {
    text-transform: capitalize;
  }

  .selected-row{
    /* background-color: #efefef !important; */
    background-color: #ffffff !important;
  }

.close.notification{
  margin: -8px -3px 0 7px
}
p.message:hover{
  background-color:#b6efd7;
  /* rgb(117, 179, 221); */
}
.FinalBoldLargeRed {
    /* font-weight: 500; */
    /* font-size: inherit; */
    background-color:#e8eef1;
    /* color: rgb(87, 228, 181) !important; */
  }
  .PDN {
    background-color:rgb(0 0 0 / 14%);;
  }
  .ordercancelled{
    background-color:  rgb(0 123 255 / 25%);
  }
</style>
<div
  class="notification-modal-sticky"
  [class.modal-sticky-hide]="!isActive"
  style="display : block; padding: 0px;"
>
  <!-- Modal content -->
  <div
    class="modal-content"
    style="box-shadow: -10px 0px 10px 1px #aaaaaa; width:32%; margin-left: 221px;margin-top: -23px;"
    *ngIf="popupsettings"
  >
  <!-- background: hsl(210,8%,95%); -->
    <div class="modal-header" style="padding: 0px;background: #81DAAC; align-items: center;
    position: relative; height: 33px;
    box-sizing: border-box;">
      <h4 class="modal-title" style="text-align: center;font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;">
        {{ popupsettings.title }}
      </h4>
      <button class="close notification" aria-label="Close" (click)="hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="col-md-12 table-scroll" style="height: 90%; padding-right: 0px;">
      <ul class="table" style="font-size: 12px; max-height: 260px !important; padding-top: 13px">
        <li
          class="highlight item" 
          [class.selected-row]="!itemObj.ISSEEN"
          *ngFor="
            let itemObj of itemList
              | paginate
                : {
                    id: tabindex,
                    itemsPerPage: pageSize,
                    currentPage: pageNumber,
                    totalItems: totalItems
                  };
            let i = index
          "
          (dblclick)="doubleClick(itemObj)"
          title="double click for detail view"
        >
          <div class="notification-card">
            <div class="notification-card-inside">
              <p class="date" style="margin-top: 3px !important;">{{itemObj.TIMESTAMP | date:'medium'}}</p>
              <p style="margin-bottom:25px;" class="message" [ngClass]="[(itemObj.TYPE == 'Sales Order')?'FinalBoldLargeRed':'message',
              (itemObj.TYPE == 'Pre Debit Note')?'PDN':'message',(itemObj.TYPE == 'Order Cancelled'? 'ordercancelled':'message')]">
                {{itemObj.MESSAGE}} 
              </p>
             
            </div>
          </div>
        </li> 
      </ul> 
      <div class="row clearfix" style="float: center; margin : 4px 0px;">
        <div class="col-md-12" style="width:50%;height: 25px;
        margin-top: -1px;" *ngIf="itemList.length">
          <pagination-controls
            [id]="tabindex"
            (pageChange)="onPageChange($event)"
            maxSize="9"
            directionLinks="true"
            autoHide="true"
            responsive="true"
            previousLabel="Previous"
            nextLabel="Next"
            screenReaderPaginationLabel="Pagination"
            screenReaderPageLabel="page"
            screenReaderCurrentLabel="You're on page" style="font-size:10px;"
          ></pagination-controls>
        </div>
        <div  style="width:100%; text-align: center; font: size 14px;" *ngIf="!itemList.length">
            No notifications
        </div>
      </div>
    </div>
  </div>
</div>

<salesorder-pending-dialog (onClickSalesOrder)="onClickSalesOrder()" #SalesOrderPendingList></salesorder-pending-dialog>