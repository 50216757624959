<div class="importdetail_container" *ngIf="isActive">
    <div class="card">
      <div class="card-header" style="background: #cbcbcb;">
        <h5 class="page-title">IMPORT DOCUMENT DETAILS</h5>
        <div class="button-group">
          <button title="Save" type="submit" class="btn btn-info" (keydown.F6)="ok()" (click)="ok()" 
            style="margin-right:2px">OK</button>
          <button type="button" title="reset" (click)="onclear()" (keydown.F3)="onclear()" class="btn btn-info"
            style="margin-right:2px">RESET</button>
          <button type="button" title="Cancel" class="btn btn-info" (click)="closeDialog()">CANCEL</button>
        </div>
      </div>
      <div class="card-body">
        <div class="row" class="form-horizontal" style="margin-top: 15px;">

          <div class="row">
            <div class="col-md-6 pd0">
              <div class="form-group row">
                <label class="col-sm-4 form-control-label" style="margin-left: -12px;">Import Document No:</label>
                <div class="col-sm-7">
                  <input #DocumentNo id="documentno" type="text" class="form-control" [(ngModel)]="_trnMainService.TrnMainObj.IMPORTDETAILS.DOCUMENTNO" placeholder="Import Document No" [disabled]="tranMode == 'VIEW'">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-2 form-control-label" style="margin-left: -12px;">L/C No.:</label>
                <div class="col-sm-7">
                  <input type="text" class="form-control" [(ngModel)]="_trnMainService.TrnMainObj.IMPORTDETAILS.LCNUM" placeholder="L/C No." [disabled]="tranMode == 'VIEW'"> 
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="form-group row">
            <label class="col-sm-2 form-control-label" style="margin-left: -12px;">Import Document No:</label>
            <div class="col-sm-5">
              <input #DocumentNo id="documentno" type="text" class="form-control" [(ngModel)]="_trnMainService.TrnMainObj.IMPORTDETAILS.DOCUMENTNO" placeholder="Import Document No">
            </div>
          </div> -->
          <!-- <div class="form-group row">
            <label class="col-sm-2 form-control-label" style="margin-left: -12px;">L/C No.:</label>
            <div class="col-sm-5">
              <input type="text" class="form-control" [(ngModel)]="_trnMainService.TrnMainObj.IMPORTDETAILS.LCNUM" placeholder="L/C No."> 
            </div>
          </div> -->

          <div class="row">
            <label class="form-control-label" style="font-weight: bold;border-bottom: 1px solid #e2e2e2;width: 130px !important;">Import Details </label>
          </div>

          <!-- <div class="form-group row">
            <label class="col-sm-3 form-control-label" style="margin-left: -12px;">Tax Value:</label>
            <div class="col-sm-5">
              <input type="number" class="form-control" [(ngModel)]="_trnMainService.TrnMainObj.IMPORTDETAILS.TAXABLE" placeholder="Tax Value"> 
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 form-control-label" style="margin-left: -12px;">Non-Taxable Value:</label>
            <div class="col-sm-5">
              <input type="number" class="form-control" [(ngModel)]="_trnMainService.TrnMainObj.IMPORTDETAILS.NONTAXABLE" placeholder="Non-Taxable Value" >
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 form-control-label" style="margin-left: -12px;">VAT Amount:</label>
            <div class="col-sm-5">
              <input type="number" class="form-control" [(ngModel)]="_trnMainService.TrnMainObj.IMPORTDETAILS.VAT" placeholder="VAT Amount">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 form-control-label" style="margin-left: -12px;">Net Amount:</label>
            <div class="col-sm-5">
              <input type="number" class="form-control" [(ngModel)]="_trnMainService.TrnMainObj.IMPORTDETAILS.NETAMOUNT" placeholder="Net Amount" >
            </div>
          </div> -->
          <div class="row" >
            <div class="col-md-12 pd0" style="height: 40vh; margin-bottom: 1rem; overflow-y: scroll;overflow-x: hidden;">
              <table class="table">
                <thead>
                  <tr style="border-bottom: 2px solid #e6e6e6; height: 25px; background: #9ccae4 ;">
                    <th scope="col" style="width: 1%;">Sn.</th>
                    <th scope="col" style="width: 10%;">Item Name</th>
                    <th scope="col" style="width: 5%;">Qty</th>
                    <!-- <th scope="col" style="width: 5%;">Rate</th>
                    <th scope="col" style="width: 5%;">Amount</th> -->
                    <th scope="col" style="width: 5%;">Taxable</th>
                    <th scope="col" style="width: 5%;">Non-Taxable</th>
                    <th scope="col" style="width: 5%;">VAT</th>
                    <th scope="col" style="width: 5%;">Net amount</th>

                  </tr>
                </thead>
                <tbody style="background-color: aliceblue;">
                    <tr *ngFor="let item of _trnMainService.TrnMainObj.IMPORTDETAILS.prodList;let i = index" style="height: 28px;"  (click)="rowClick(i)" (keydown.Shift.delete)="deleteRow(i)"> 
                      <td style="width: 1%;">{{i+1}}</td>
                      <td style="width: 10%;">
                        <input id="importItemname{{i}}" type="text" list="product" [(ngModel)]="item.ITEMNAME" (keydown.Enter)="masterservice.focusAnyControl('importQty'+i)" (focus)="rowClick(i)" [disabled]="tranMode == 'VIEW'" autocomplete="off">
                        <datalist id="product">
                          <option *ngFor="let i of productList" [value]="i.ITEMDESC">
                        </datalist>
                        <!-- <select id="itemName{{i}}">
                          <option [value]=""></option>
                          <option *ngFor="let i of productList" [value]="i.MCODE">{{i.ITEMDESC}}</option>


                      </select> -->
                    </td>
                      <td  style="width: 5%;"><input type="number" id="importQty{{i}}" [(ngModel)]="item.QUANTITY" (keydown.Enter)="masterservice.focusAnyControl('importTaxable'+i)" (change)="onQuantityChange()" [disabled]="tranMode == 'VIEW'"></td>
                      <td  style="width: 5%;"><input type="number" id="importTaxable{{i}}"  [(ngModel)]="item.TAXABLE" (keydown.enter)="masterservice.focusAnyControl('importNonTaxable'+i)" (change)="onTaxableChange()" [disabled]="tranMode == 'VIEW'"></td>
                      <td  style="width: 5%;"><input type="number" id="importNonTaxable{{i}}" [(ngModel)]="item.NONTAXABLE"  (keydown.enter)="masterservice.focusAnyControl('importVAT'+i)" (change)="onNonTaxableChange()" [disabled]="tranMode == 'VIEW'"></td>
                      <td  style="width: 5%;"><input type="number" id="importVAT{{i}}" [(ngModel)]="item.VAT"  (keydown.enter)="masterservice.focusAnyControl('importNetmant'+i)" (change)="onVatChange()" [disabled]="tranMode == 'VIEW'"></td>
                      <td  style="width: 5%;"><input type="number" id="importNetmant{{i}}" [(ngModel)]="item.NETAMOUNT"  (keydown.enter)="nextRow(i)" (keydown.tab)="nextRow(i)" [disabled]="tranMode == 'VIEW'" ></td>

                    </tr>
                </tbody>
               
              </table>
            </div>
          </div>



          <div class="row" >
            <table class="table">
              <tr style="line-height: 1.2rem;">
                <th style="background: rgb(172 172 172); border: 2px white solid;" width="11%">TOTAL</th>
        <th style="background: rgb(172 172 172); border: 2px white solid;" width="5%">
          {{_trnMainService.TrnMainObj.IMPORTDETAILS.TOTALQTY}} </th>
          <th style="background: rgb(172 172 172); border: 2px white solid;" width="5%">
            {{_trnMainService.TrnMainObj.IMPORTDETAILS.TOTALTAXABLE}} </th>
        <th style="background: rgb(172 172 172); border: 2px white solid;" width="5%">
          {{_trnMainService.TrnMainObj.IMPORTDETAILS.TOTALNONTAXABLE}}</th>
        <th style="background: rgb(172 172 172); border: 2px white solid;" width="5%">
          {{_trnMainService.TrnMainObj.IMPORTDETAILS.TOTALVAT}}</th>
        <th style="background: rgb(172 172 172); border: 2px white solid;" width="5%">
          {{_trnMainService.TrnMainObj.IMPORTDETAILS.NETAMOUNT}}</th>
              </tr>
            </table>
            <!-- <div class="col-md-2">
              <h6>TOTAL QTY: {{_trnMainService.TrnMainObj.IMPORTDETAILS.TOTALQTY}} </h6>
            </div>
            <div class="col-md-2">
              <h6>TAXABLE: {{_trnMainService.TrnMainObj.IMPORTDETAILS.TOTALTAXABLE}} </h6>
            </div>
            <div class="col-md-3">
              <h6>NON-TAXABLE: {{_trnMainService.TrnMainObj.IMPORTDETAILS.TOTALNONTAXABLE}} </h6>
            </div>
            <div class="col-md-2">
              <h6>VAT: {{_trnMainService.TrnMainObj.IMPORTDETAILS.TOTALVAT}} </h6>
            </div>
            <div class="col-md-2">
              <h6>NET AMNT: {{_trnMainService.TrnMainObj.IMPORTDETAILS.NETAMOUNT}} </h6>
            </div> -->
          </div>


        </div>
      </div>
      <!-- <div class="card-footer" *ngIf="tranMode == 'VIEW'">
          <button type="button" class="btn btn-primary btn-sm pull-right" (click)="saveImportDetails()">SAVE</button>
      </div> -->
    </div>
  
  </div>