import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@app/_helperServices/authService.service';
import { GlobalState } from '@app/_helperServices/global.state';
import { Subject, Observable, of } from 'rxjs';
import { Brand, Model, TBarcode, MultiStockLevel, Product, ProductGroup, prodObj } from '@app/common/interfaces/ProductItem';
import { IMSGridSettings, ActionKeyMaster } from '@app/common/popup/ims-grid/ims-grid.component';
import { MasterRepo } from '@app/_services/masterRepo.service';
import { environment } from '@environments/environment';
import { AlertService } from '@app/common/shared/alert/alert.service';
import { ActivatedRoute } from '@angular/router';



@Injectable()

export class ProductMasterService {
  public searchItemList: any[] = [];
  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx';
  PBarCodeList: TBarcode[] = [];
  MultiStockList: MultiStockLevel[] = [];
  MCAT_Default: string;
  productObj: Product = <Product>{};
  pObj: any = <any>{};
  isrowClicked: boolean = false;
  suppilierName: any;
  TempProductObj: any = <any>{}
  treeID:any;
  sortProduct:any;
  sortProductGrp:any;
  groupSelectObj: ProductGroup = <ProductGroup>{};
  selectedGroupMenucode: string;
  userProfile: any = <any>{};
  activepathurl: any;

  
  constructor(private http: HttpClient,
    private state: GlobalState, public authservice:AuthService,
    public alertService: AlertService,
    public arouter: ActivatedRoute
    )
    {
    this.userProfile = authservice.getUserProfile();

    }
  private get apiUrl(): string {
   // let url = this.state.getGlobalSetting("apiUrl");
   let url = environment.apiUrl;
    let apiUrl = "";

    if (!!url && url.length > 0) { apiUrl = url };
    return apiUrl
  }
  getParentWiseProduct(BrandName: string) {
    return this.http.get(this.apiUrl + '/getParentWiseProduct/' + BrandName);
  }
  getSearchProducts(searchkey) {
    return this.http.get(this.apiUrl + '/getSearchProducts/' + searchkey);
  }
  getAutoGenerateMenuCode(MGROUP,PARENT) {

    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    
    this.http.get(this.apiUrl + '/getNewMenucode/' + MGROUP +'/' + PARENT).subscribe(
      response => {
        let data = response;
        if (data['status'] == 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();

        }
      }
      , error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      });

    return returnSubject;
  }
  getUniqueMCode() {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl + '/getUniqueMcode').subscribe(
      response => {
        let data = response;
        if (data['status'] == 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();

        }
      }
      , error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      });

    return returnSubject;
  }
  getColorGroup() {
    return this.http.get(`${this.apiUrl}/getAllProductColorGroup`);
  }

  getProduct(mcode: string) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl + '/getProduct/' + mcode).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();

      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }
  getProductForEdit(mcode: string) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl + '/getProductByMcode/' + mcode).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }


  getProductPriceForView(mcode: string) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl + '/getProductPriceByMcode/' + mcode).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }





  getInitialValuesForNewProduct(mcode: string) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl + '/getInitialValuesForNewProduct/' + mcode).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();

      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }
  


  saveBrand(brand: Brand) {
    let res = { status: "error", result: "" }
    let returnSubject: Subject<any> = new Subject();
    let bodyData = brand;
    this.http.post(this.apiUrl + "/saveBrand", bodyData)
      .subscribe(data => {
        let retData = data;
        
        if (retData['status'] == "ok") {
          res.status = "ok";
          res.result = retData['result']
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
        else {
          res.status = "error1"; res.result = retData['result'];
          
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      },
        error => {
          res.status = "error2", res.result = error;
          
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;

  }
  saveModel(model: Model) {
    let res = { status: "error", result: "" }
    let returnSubject: Subject<any> = new Subject();
    let bodyData = model;
    // var  data = JSON.stringify(bodyData, undefined, 2);
    this.http.post(this.apiUrl + "/saveModel", bodyData)
      .subscribe(data => {
        let retData = data;
        
        if (retData['status'] == "ok") {
          res.status = "ok";
          res.result = retData['result']
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
        else {
          res.status = "error1"; res.result = retData['result'];
          
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      },
        error => {
          res.status = "error2", res.result = error;
          
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;

  }

  getListFromKey(url, key) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl + url + key).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }

  getUploadFile(modal: any) {
    let res = { status: "error", result: "error" }
    let returnSubject: Subject<any> = new Subject();
    let bodyData = modal;
    this.http.post(this.apiUrl + "/SaveImportMenuitemFile", bodyData).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }
  private _ItemList: any[] = [];
  public _ItemListObservable$: Observable<any[]>;

  public GetAllProducts() {
    try {
      if (this._ItemList.length > 0) {
        return of(this._ItemList);
      } else if (this._ItemListObservable$) {
        return this._ItemListObservable$;
      } else {
        this._ItemListObservable$ = null;
        this._ItemListObservable$ = this.http
          .get(this.apiUrl + "/AllProducts")
          .map(data => {
            this._ItemList.push(<any>data);
            return this._ItemList;
          })
        return this._ItemListObservable$;
      }
    } catch (ex) {
    }
  }


  //   exportAsExcelFile(json: any[], excelFileName: string): void {
  //     const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  //     const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  //     const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
  //     this.saveAsExcelFile(excelBuffer, excelFileName);
  //   }

  //   saveAsExcelFile(buffer: any, fileName: string): void {
  //     const data: Blob = new Blob([buffer], {
  //       type: this.EXCEL_TYPE
  //     });
  //     FileSaver.saveAs(data, fileName + this.EXCEL_EXTENSION);
  //   }

  productMasterTableSetting(organizationType) {
    return {
      mode: "external",
      add: {
        addButtonContent: '',
      },
      view: {
        viewButtonContent: 'View',
        saveButtonContent: '<i class="ion-checkmark"></i>',
        cancelButtonContent: '<i class="ion-close"></i>',
      },
      edit: {
        editButtonContent: 'Edit',
        saveButtonContent: '<i class="ion-checkmark"></i>',
        cancelButtonContent: '<i class="ion-close"></i>',

      },
      delete: {
        deleteButtonContent: ' ',
        confirmDelete: true
      },
      columns: {
        MENUCODE: {
          title: 'Product Code',
          type: 'number'
        },
        DESCA: {
          title: 'Product Name',
          type: 'string'
        },
        MotherPack: {
          title: 'Mother Pack',
          type: 'string'
        },
        Brand: {
          title: 'Brand',
          type: 'string'
        },
        BusinessUnit: {
          title: 'Business Unit',
          type: 'string'
        },
        STATUS: {
          title: 'Status',
          type: 'string',
          width: '9%',
          valuePrepareFunction: (value) => { return value == 1 ? 'Active' : 'InActive'; },
          filter: {
            type: 'list',
            config: {
              selectText: 'Show all',
              list: [
                { value: 1, title: 'Active' },
                { value: 0, title: 'InActive' },
              ]
            }
          }
        }
      }
    };


  }
  imsGridSettings: IMSGridSettings = new IMSGridSettings();
  imsGridSettingsforProductList: IMSGridSettings = new IMSGridSettings();
  imsGridSettingsforGroupList: IMSGridSettings = new IMSGridSettings();


  getimsgridSetting() {
    return this.imsGridSettings = {
      title: "All Product",
      apiEndpoints: `/GetAllProduct`,
      pageSize: 10,
      showActionButton: true,
      columns: [

        {
          key: "DESCA",
          title: "Item Name",
          hidden: false,
          noSearch: false,
          type: "string",
          width: '250px'

        },
        {
          key: "MENUCODE",
          title: "Item Code",
          hidden: false,
          noSearch: false,
          type: "string",
          width: '112px'
        },
        {
          key: "BASEUNIT",
          title: "Unit",
          hidden: false,
          noSearch: false,
          type: "string",
          width: '70px'
        },
        {
          key: "PRATE_A",
          title: "P-Rate",
          hidden: false,
          noSearch: false,
          type: "string",
          width: '85px'
        },
        {
          key: "RATE_A",
          title: "S-Rate",
          hidden: false,
          noSearch: false,
          type: "string",
          width: '85px'
        },
        {
          key: "VAT",
          title: "Vat",
          hidden: false,
          width: '72px',
          noSearch: false,
          type: "string",

        },
        {
          key: "PARENTS",
          title: "Parent",
          hidden: false,
          noSearch: false,
          type: "string",
          width: '160px'
        },
        {
          key: "status",
          title: "Status",
          width: '104px',
          valuePrepareFunction: value => {
            return value == 1 ? "Active" : "InActive";
          }
        }

      ],
      actionKeys: [
        {
          text: "view",
          title: "View",
          icon: "fa fa-view",
          type: ActionKeyMaster.VIEW
        },
        {
          text: "Edit",
          title: "Edit",
          icon: "fa fa-eye",
          type: ActionKeyMaster.EDIT
        }
      ]
    };
  }

  getProductList() {
    return this.imsGridSettingsforProductList = {
      title: "All Product",
      apiEndpoints: `/getAllProductList`,
      pageSize: 10,
      showActionButton: true,
      columns: [

        {
          key: "DESCA",
          title: "Item Name",
          hidden: false,
          noSearch: false,
          type: "string",
          width: '250px'

        },
        {
          key: "ITEM_CODE",
          title: "Item Code",
          hidden: false,
          noSearch: false,
          type: "string",
          width: '112px'
        },
        {
          key: "UNIT",
          title: "Unit",
          hidden: false,
          noSearch: false,
          type: "string",
          width: '70px'
        },
        {
          key: "PRATE",
          title: "P-Rate",
          hidden: false,
          noSearch: false,
          type: "string",
          width: '85px'
        },
        {
          key: "SRATE",
          title: "S-Rate",
          hidden: false,
          noSearch: false,
          type: "string",
          width: '85px'
        },
        {
          key: "VAT",
          title: "Vat",
          hidden: false,
          width: '72px',
          noSearch: false,
          type: "string",

        },
        {
          key: "PARENT",
          title: "Parent",
          hidden: false,
          noSearch: false,
          type: "string",
          width: '160px'
        },
        //  {
        //    key: "status",
        //    title: "Status",
        //    width:'104px',
        //    valuePrepareFunction: value => {
        //      return value == 1 ? "Active" : "InActive";
        //    }
        //  }
        {
          key: "SUPPLIER",
          title: "Supplier",
          hidden: false,
          noSearch: false,
          type: "string",
          width: '160px'
        },
        {
          key: "BARCODE",
          title: "Barcode",
          hidden: false,
          noSearch: false,
          type: "string",
          width: '160px'
        },
        {
          key: "MCODE",
          title: "Mcode",
          hidden: false,
          noSearch: false,
          type: "string",
          width: '160px'
        },
      ],
      actionKeys: [
        {
          text: "view",
          title: "View",
          icon: "fa fa-view",
          type: ActionKeyMaster.VIEW
        },
        {
          text: "Edit",
          title: "Edit",
          icon: "fa fa-eye",
          type: ActionKeyMaster.EDIT
        }
      ]
    };
  }

  getGroupList() {
    return this.imsGridSettingsforGroupList = {
      title: "All Group",
      apiEndpoints: `/getAllGroupList`,
      pageSize: 10,
      showActionButton: true,
      columns: [

        {
          key: "DESCA",
          title: "Group Name",
          hidden: false,
          noSearch: false,
          type: "string",
          width: '250px'

        },
        {
          key: "PARENT_GROUP",
          title: "Parent Group",
          hidden: false,
          noSearch: false,
          type: "string",
          width: '112px'
        },
        {
          key: "GROUP_CODE",
          title: "Group Code",
          hidden: false,
          noSearch: false,
          type: "string",
          width: '70px'
        },
        {
          key: "REC_MARGIN",
          title: "Rec Margin",
          hidden: false,
          noSearch: false,
          type: "string",
          width: '85px'
        },
        {
          key: "CATEGORY",
          title: "Category",
          hidden: false,
          noSearch: false,
          type: "string",
          width: '85px'
        },
        {
          key: "EXTRA_CATEGORY",
          title: "Extra Catagory",
          hidden: false,
          width: '72px',
          noSearch: false,
          type: "string",

        },
        {
          key: "MCODE",
          title: "Mcode",
          hidden: false,
          noSearch: false,
          type: "string",
          width: '160px'
        },
      ],
      actionKeys: [
        {
          text: "view",
          title: "View",
          icon: "fa fa-view",
          type: ActionKeyMaster.VIEW
        },
        {
          text: "Edit",
          title: "Edit",
          icon: "fa fa-eye",
          type: ActionKeyMaster.EDIT
        }
      ]
    };
  }

  getMCATbyId(mcode: string) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl + '/getMCATbyId/' + mcode).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();

      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }
  getMCAT1byId(mcode: string) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl + '/getMCAT1byId/' + mcode).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();

      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }
  getRecMargin(mcode: string) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl + '/getRecMargin/' + mcode).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();

      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }
  getFCodeFromTreeNode(mcode: string) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl + '/getFcodeFromTreeNode/' + mcode).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();

      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }
  getECODE(mcode: string, fcode: number) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl + '/getMaxECode/' + mcode + '/' + fcode).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();

      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }

  deleteMenuitem(mcode: string,isGroup : boolean) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl + '/DeleteProductMasterFromMCode/' + mcode +'/' + isGroup).subscribe(response => {
      let data = response;
      
      if (data['status'] == 'ok') {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    });
    return returnSubject;
  }
  nullToZeroConverter(value) {
    if (
      value === undefined ||
      value == null ||
      value === '' ||
      value === 'Infinity' ||
      value === 'NaN' ||
      isNaN(parseFloat(value))
    ) {
      return 0;
    }
    return parseFloat(value);
  }

  getProductGroupListByID(GroupID: string,ParentID:string,HasChild:number) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    let bodyData = { ID: GroupID,PARENTID:ParentID,HASCHILD:HasChild };
    this.http.post(this.apiUrl + '/getProductGroupListByID', bodyData).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }


  getProductListByID(GroupID: string,ParentID:string,HasChild:number) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    let bodyData = { ID: GroupID,PARENTID:ParentID,HASCHILD:HasChild };
    this.http.post(this.apiUrl + '/getProductListByID', bodyData).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }

  getMCODEusingBCODE(BCODE: string) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    let bodyData = { ID: BCODE };
    this.http.post(this.apiUrl + '/getMCODEusingBCODE', bodyData).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }

  getMCODEusingBARCODE(BCODE: string) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    let bodyData = { ID: BCODE };
    this.http.get(this.apiUrl + '/getMCODEusingBARCODE/'+BCODE).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }

  getChildrenGrpAndItem(mcode: string) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl + '/getChildrenGrpAndItem/' + mcode).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }

  getProductListByIDandDate(GroupID: string,ParentID:string,HasChild:number,sort:any) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    let bodyData = { ID: GroupID ,PARENTID:ParentID,HASCHILD:HasChild,sort:sort};
    this.http.post(this.apiUrl + '/getProductListByID', bodyData).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }

  getProductGroupListByIDandDate(GroupID: string,ParentID:string,HasChild:number,sort:any) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    let bodyData = { ID: GroupID,PARENTID:ParentID,HASCHILD:HasChild,sort:sort };
    this.http.post(this.apiUrl + '/getProductGroupListByID', bodyData).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }

  public updateBarcodeMapping(voucherno) {
    return this.http
      .post(this.apiUrl + '/UpdateBarcodeMapping', voucherno)
  }

  public checkDuplicateBarcode(barcode,mcode){
    if(mcode == undefined){
      mcode = "null";
    }
    let res = {status: 'error', result: ""};
    let returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl+'/checkDuplicateBarcode/'+barcode+'/'+mcode)
    .subscribe( response =>{
        let data = response;
        if(data['status'] == 'ok'){
            returnSubject.next(data);
            returnSubject.unsubscribe();
        }else{
            returnSubject.next(data)
            returnSubject.unsubscribe();
        }
    }, error =>{
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
    });
    return returnSubject
  }

  public getMainGroupList(){
    let res = {status: 'error', result: ""};
    let returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl+'/getMainGroupList')
    .subscribe( response =>{
        let data = response;
        if(data['status'] == 'ok'){
            returnSubject.next(data);
            returnSubject.unsubscribe();
        }else{
            returnSubject.next(data)
            returnSubject.unsubscribe();
        }
    }, error =>{
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
    });
    return returnSubject;
  }

  public getSubGroupList(groupID){
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl+'/getSubGroupList?SELECTEDGROUPID='+groupID)
        .subscribe(response => {
            let data = response;
            if (data['status'] == 'ok') {
                returnSubject.next(data);
                returnSubject.unsubscribe();
            }
            else {
                returnSubject.next(data)
                returnSubject.unsubscribe();
            }
        }, error => {
            res.status = 'error2'; res.result = error;
            returnSubject.next(res);
            returnSubject.unsubscribe();
        }
        );
    return returnSubject;
  }

  public getProductInfo(groupID){
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl+'/getProductInfo?GROUPID='+groupID)
        .subscribe(response => {
            let data = response;
            if (data['status'] == 'ok') {
                returnSubject.next(data);
                returnSubject.unsubscribe();
            }
            else {
                returnSubject.next(data)
                returnSubject.unsubscribe();
            }
        }, error => {
            res.status = 'error2'; res.result = error;
            returnSubject.next(res);
            returnSubject.unsubscribe();
        }
        );
    return returnSubject;
  }

  public getGroupHierarchy(groupID){
    let res = {status:"error",result:""};
    let returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl+'/getGroupHierarchy?GROUPID='+groupID)
    .subscribe(response =>{
      let data = response;
      if(data['status']=='ok'){
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
      else{
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error=>{
      res.status = 'error2'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    });
    return returnSubject;
  }


  
  public checkProductTransaction(groupID){
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl+'/checkProductTransaction?GROUPID='+groupID)
        .subscribe(response => {
            let data = response;
            if (data['status'] == 'ok') {
                returnSubject.next(data);
                returnSubject.unsubscribe();
            }
            else {
                returnSubject.next(data)
                returnSubject.unsubscribe();
            }
        }, error => {
            res.status = 'error2'; res.result = error;
            returnSubject.next(res);
            returnSubject.unsubscribe();
        }
        );
    return returnSubject;
  }

  // checkUserRights(value):boolean{
  //   // this.activepathurl = this.arouter.snapshot.url[0];


  //   console.log("active",this.arouter.snapshot.url[0]);
  //   const right = this.userProfile.menuRights.find( x => x.menu == this.activepathurl);

  //   if(right){
  //     let userrights = right.right;
  //     console.log("user right", userrights);
  //     let xyz ; let allow:boolean = true;
  //     switch(value){
  //       case 'add':
  //         xyz = userrights.find( x=> x =='add');
  //         if(xyz == undefined){
  //           allow = false;
  //         }
  //         break;

  //       case 'edit':
  //         xyz = userrights.find( x => x == "xxx");
  //         if(xyz == undefined){
  //           allow = false;
  //         }
  //         break;
        
  //       case 'delete':
  //         xyz = userrights.find( x => x == "delete");
  //         if(xyz == undefined){
  //           allow = false;
  //         }
  //         break;

  //       case 'print':
  //         xyz = userrights.find( x => x == "print");
  //         if(xyz == undefined){
  //           allow = false;
  //         }
  //         break;

  //       case 'view':
  //         xyz = userrights.find( x => x == "view");
  //         if(xyz == undefined){
  //           allow = false;
  //         }
  //         break;

  //       default:
  //         break;
  //     }
  //     // console.log("allowedd?", allow);
  //     if(allow == false){
  //       this.alertService.warning("Current user has no rights to "+value);
  //       return false;
  //     }else{
  //       return true;
  //     }
  //   }
  // }


  getProductListByMRP(GroupID: string,ParentID:string,HasChild:number,sort:any) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    let bodyData = { ID: GroupID ,PARENTID:ParentID,HASCHILD:HasChild,sort:sort};
    this.http.post(this.apiUrl + '/getProductListByID', bodyData).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }


}


