import {
    Component,
    Input,
    Output,
    EventEmitter,
    Injector,
    HostListener,
    OnChanges,
    ElementRef,
    ViewChild,
    AfterViewInit
  } from '@angular/core';
  import { PagedListingComponentBase } from '../../../paged-list-component-base';
  import { first, tap } from 'rxjs/operators';
  import { debounceTime } from 'rxjs/operators';
  import { MatDialog } from '@angular/material/dialog';
  import { environment } from '@environments/environment';
  import { AlertService } from '@app/common/shared/alert/alert.service';
  
  @Component({
    selector: 'multiselect-generic-grid',
    templateUrl: './multiselect-generic-grid.component.html',
  })
  export class MultiselectGenericPopUpComponent extends PagedListingComponentBase
    implements OnChanges, AfterViewInit {
    selectedItems: any[] = [];
    constructor(public injector: Injector,
      public dialog: MatDialog,
      public alertservice: AlertService) {
      super(injector);
    }
    /** List Declaration  */
  
    requestUrl = '';
  
    isActive = false;
    itemList: any[] = [];
    selectedRowIndex = 0;
    tabindex = 'list';
    hideGridOnDoubleClick = true;
    // for transaction filter
    billTo = '';
    tag = '';
    isForCancelOrder = false;
  
    /** Output  */
  
    @Output() onPopUpClose = new EventEmitter();
    @Output() onItemDoubleClick = new EventEmitter();
    @Output() onDeleteClicked = new EventEmitter();
    @Output() onItemsSelected = new EventEmitter();
  
    /** Input  */
  
    @Input() popupsettings: MultiSelectGenericPopUpSettings;
    @Input() summary: string;
    @ViewChild('inputBox') inputBox: ElementRef;
    ngAfterViewInit(): void {
  
  
      this.filterValue.valueChanges.pipe(
        debounceTime(500)
      ).subscribe(data => {
        if(data.includes("&")){
          this.alertservice.warning("Cannot search using Special Characters.");
          return;
        }
        this.refreshPage();
        this.refresh();
      });
    }
  
    show(billTo: string = '', isForCancelOrder: boolean = false, tag: string = '', hideOnDoubleClick: boolean = true) {
  
  
      this.hideGridOnDoubleClick = hideOnDoubleClick;
      setTimeout(() => {
        this.inputBox.nativeElement.focus();
      }, 10);
      this.summary = null;
      this.billTo = billTo;
      this.isForCancelOrder = isForCancelOrder;
      this.itemList = [];
      this.isActive = true;
      this.selectedRowIndex = 0;
      this.tag = tag;
      setTimeout(() => {
        this.setFilterOption();
        // this.refreshPage();
        // this.refresh();
      }, 100);
    }
  
    setFilterOption() {
      if (this.popupsettings && this.popupsettings.columns.length) {
        const filterIndex = this.popupsettings.defaultFilterIndex ? this.popupsettings.defaultFilterIndex : 0;
        if (this.popupsettings.columns.length <= filterIndex) { return; }
  
        this.filterValue.setValue('');
        this.filterOption = this.popupsettings.columns[filterIndex].key;
      }
    }
  
    getData() {
      this.summary = null;
      this.selectedRowIndex = 0;
      let apiUrls = environment.apiUrl;
      const apiEndpoints = this.popupsettings.apiEndpoints;
      let apiUrl = `${apiUrls}${apiEndpoints}?currentPage=${
        this.pageNumber
        }&maxResultCount=${this.pageSize}`;
  
      if (this.billTo && this.billTo !== '' && this.billTo != null && this.billTo !== undefined) {
        apiUrl = apiUrl + `&billTo=${this.billTo}`;
      }
      if (this.tag && this.tag !== '' && this.tag != null && this.isTableLoading !== undefined) {
        apiUrl = apiUrl + `&tag=${this.tag}`;
      }
      if (this.isForCancelOrder) {
        apiUrl = apiUrl + `&isForCancelOrder=${this.isForCancelOrder}`;
      }
  
      this.requestUrl = this.getFilterOption(apiUrl);
  
      return this._http
        .get(this.requestUrl).
        pipe(first()).subscribe((res: any) => {
          this.totalItems = res ? res.totalCount : 0;
          this.itemList = res ? res.data : [];
  
          this.itemList.forEach(function (item) {
            if (item.TRNDATE != null && item.TRNDATE !== undefined) {
              item.TRNDATE = item.TRNDATE.toString().substring(0, 10);
            }
            if (item.DATE != null && item.DATE !== undefined) {
              item.DATE = item.DATE.toString().substring(0, 10);
            }
          });
          if (this.itemList[this.selectedRowIndex] != null) {
            this.itemList[this.selectedRowIndex].itemSummary;
          }
  
          if (
            this.itemList.length > 0 &&
            this.selectedRowIndex === 0 &&
            this.itemList[this.selectedRowIndex].itemSummary
          ) {
            this.summary = this.itemList[this.selectedRowIndex].itemSummary;
          }
        });
    }
  
    hide() {
      this.itemList = [];
      this.pageNumber = 1;
      this.totalItems = 0;
      this.isActive = false;
    }
  
  
  
    triggerSearch() {
      if (
        this.filterOption == null ||
        this.filterOption === undefined ||
        this.filterOption === ''
      ) {
        return;
      }
      this.filterValue.setValue('');
      // this.refreshPage();
      // this.refresh();
    }
  
    singleClick(index) {
      this.selectedRowIndex = index;
      this.summary = this.itemList[index].itemSummary;
    }
  
    doubleClick(event) {
        console.log("double clicked item", event);
        let duplicate = this.selectedItems.filter(x => x.MCODE == event.MCODE);
        console.log("duplicate", duplicate)
        if(duplicate.length > 0){
            return;
        }
        this.selectedItems.push(event);
        console.log("selected item lisr", this.selectedItems)
    //   this.onItemDoubleClick.emit($event);
    //   if (this.hideGridOnDoubleClick) {
    //     this.hide();
    //   }
  
    }

    selectMultipleItem(){
        // console.log("selected mul tem", this.selectedItems);
        let selectedMCODE  = '';
        let selectedProduct = '';
        this.selectedItems.forEach( x =>{
            selectedMCODE  += `${x.MCODE},`;
            selectedProduct += `${x.DESCA},`;
        })
        let a = selectedMCODE.substring(0,selectedMCODE.length-1);
        let b = selectedProduct.substring(0,selectedProduct.length-1)
        // console.log("selected mcodess", a, "selected desca", b);
        let obj = {MCODE: a, DESCA: b};
        this.onItemsSelected.emit(obj);
           if (this.hideGridOnDoubleClick) {
        this.hide();
      }

    }
  
    @HostListener('document : keydown', ['$event'])
    @debounce(10)
    updown($event: KeyboardEvent) {
      if (!this.isActive) { return true; }
      if ($event.code === 'ArrowDown') {
        $event.preventDefault();
        this.selectedRowIndex++;
        if (this.itemList[this.selectedRowIndex] != null) {
          this.summary = this.itemList[this.selectedRowIndex].itemSummary;
        }
        this.calculateTotalPages();
        if (
          this.selectedRowIndex === this.itemList.length &&
          this.pageNumber < this.totalPages
        ) {
          this.pageNumber = this.pageNumber + 1;
          this.refresh();
          this.selectedRowIndex = 0;
          this.itemListClosed();
        } else if (
          this.selectedRowIndex === this.itemList.length &&
          this.pageNumber === this.totalPages
        ) {
          this.selectedRowIndex = this.itemList.length - 1;
        }
      } else if ($event.code === 'ArrowUp') {
        $event.preventDefault();
        this.selectedRowIndex--;
        if (this.itemList[this.selectedRowIndex] != null) {
          this.summary = this.itemList[this.selectedRowIndex].itemSummary;
        }
        if (this.selectedRowIndex === -1 && this.pageNumber > 1) {
          this.pageNumber = this.pageNumber - 1;
          this.refresh();
          this.selectedRowIndex = this.itemList.length - 1;
          this.itemListClosed();
        } else if (this.selectedRowIndex === -1 && this.pageNumber === 1) {
          this.selectedRowIndex = 0;
        }
      } else if (
        ($event.code === 'Enter' || $event.code === 'NumpadEnter') &&
        this.selectedRowIndex >= 0 &&
        this.selectedRowIndex < this.itemList.length
      ) {
        $event.preventDefault();
        if (this.filterOption === 'BARCODE') { } else {
          this.onItemDoubleClick.emit(this.itemList[this.selectedRowIndex]);
          this.hide();
          this.itemListClosed();
        }
      } else if ($event.code === 'Escape') {
        $event.preventDefault();
        this.hide();
        this.itemListClosed();
      } else if ($event.code === 'ArrowRight') {
        $event.preventDefault();
        this.calculateTotalPages();
        if (this.pageNumber >= this.totalPages) {
          this.pageNumber = this.totalPages;
          return;
        }
  
        this.selectedRowIndex = 0;
        this.pageNumber = this.pageNumber + 1;
        this.refresh();
      } else if ($event.code === 'ArrowLeft') {
        $event.preventDefault();
        if (this.pageNumber <= 1) {
          this.pageNumber = 1;
          return;
        }
        this.selectedRowIndex = 0;
        this.pageNumber = this.pageNumber - 1;
        this.refresh();
  
      }
    }
  
    itemListClosed() {
      this.onPopUpClose.emit();
    }
  
    ngOnChanges(changes: any) {
      this.popupsettings = changes.popupsettings.currentValue;
    }

    removeItem(index){
        this.selectedItems.splice(index,1);
    }
  
    // CreateCustomerPopup
    // onCreateCustomer() {
    //   const dialogBatchRef = this.dialog.open(CreateCustomerDialogComponent, {
    //     width: '360px',
    //   });
    // }
  
    onActionClicked(actionString: string, index: number) {
      if (actionString === '' || actionString == null || actionString === undefined) {
        return;
      } else {
        const action = actionString.toLowerCase();
        switch (action) {
          case 'delete':
            if (confirm(`Are you sure to ${action} this row ?.`)) {
              this.onDeleteClicked.emit(this.itemList[index]);
            }
            break;
          case 'cancel':
            if (confirm(`Are you sure to ${action} this row ?.`)) {
              this.onDeleteClicked.emit(this.itemList[index]);
            }
            break;
          default:
            break;
        }
      }
    }
  }
  
  export class MultiSelectGenericPopUpSettings {
    title?: string;
    apiEndpoints?: string;
    columns?: ColumnSettings[] = [];
    defaultFilterIndex?= 0;
    showActionButton?= false;
    actionKeys?: ActionKeySettings[] = [];
  }
  
  export class ColumnSettings {
    key?: string;
    title?: string;
    hidden?= false;
    noSearch?= false;
    alignment?:any;
    pipe?:any = false;
  }
  export class ActionKeySettings {
    icon?= '';
    text?= '';
    title?= '';
  
  }
  
  export function debounce(delay: number): MethodDecorator {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
  
      const original = descriptor.value;
      const key = `__timeout__${propertyKey}`;
  
      descriptor.value = function (...args) {
        clearTimeout(this[key]);
        this[key] = setTimeout(() => original.apply(this, args), delay);
      };
  
      return descriptor;
    };
  }
  