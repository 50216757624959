import { Component, OnInit, HostListener, ElementRef, ViewChild, Input} from '@angular/core';
import { MasterRepo } from '@app/_services/masterRepo.service';
import { TransactionService } from '@app/common/Transaction Components/transaction.service';
import { SpinnerService } from '@app/common/shared/spinner/spinner.service';
import { AlertService } from '@app/common/shared/alert/alert.service';
// import { importDetails, importDetailsTotal } from '@app/common/interfaces/importDetails.interface';
import { IMPORT_DETAILS_PROD, TrnProd } from '@app/common/interfaces/TrnMain';

@Component({
  selector: 'import-purchase-details',
  templateUrl: './import-purchase-details.component.html',
  styleUrls: ['./import-purchase-details.component.css'],
})
export class ImportPurchaseDetails implements OnInit {
  isActive:boolean;
  // @ViewChild ('DocumentNo') DocumentNo:ElementRef;
  @Input() tranMode: string;
  productList: any = [];
  importrowindex: number = 0;
  
  constructor(
    public masterservice: MasterRepo,
    public _trnMainService: TransactionService, 
    public spinnerService:SpinnerService,
    public alertservice: AlertService
  ) {
    if (_trnMainService.TrnMainObj.IMPORTDETAILS == null) {
        this._trnMainService.TrnMainObj.IMPORTDETAILS = <any>{};
      }
  }

  ngOnInit() {
    // console.log("MODE IMPOR DETAILS",this.tranMode);
   }

  onclear() {
    this._trnMainService.TrnMainObj.IMPORTDETAILS = <any>{};
    this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList = [];

    this.addRow();
}

  closeDialog() {
    this._trnMainService.TrnMainObj.IMPORTDETAILS = <any>{};
    this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList = [];
    this.isActive=false;
    // this.importDetailsArray = [];
  }

  show() {
    this.isActive = true;
    // setTimeout(() => {
    //   this.DocumentNo.nativeElement.focus();
    // }, 10);
    setTimeout(()=>{
      document.getElementById("documentno").focus();
    },500);
   
    if((!this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList) || (!this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList.length) || (this._trnMainService.TrnMainObj.IMPORTDETAILS == null)){
      this._trnMainService.TrnMainObj.IMPORTDETAILS = <any>{};
      this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList = [];
       this.addRow();

    }

    this.productList = this._trnMainService.TrnMainObj.ProdList.filter(x=> x.MCODE !=  null && x.MENUCODE != null);
    console.log("product list", this.productList);
}

hide() {
    this.isActive = false;
}

ok(){
   
    this.isActive  = false;
}

  @HostListener("document : keydown", ["$event"])
  handleKeyDownboardEvent($event: KeyboardEvent) {
    if ($event.code == "Escape") {
      $event.preventDefault();
        this.closeDialog();
    }    
    }

    addRow(){
      try{
        console.log("add row");
        var newRow = <IMPORT_DETAILS_PROD>{};
      //   let item = this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList.filter( x=> x.ITEMNAME == "" || x.ITEMNAME == null || x.ITEMNAME == undefined)
      //  if(item.length > 0) return;
        this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList.push(newRow);
      }catch(ex){
        console.log(ex);
        alert(ex);
      }
    }

    rowClick(index){
      this.importrowindex = index;
    }
    
    nextRow(index){
      if (this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList[index].ITEMNAME == "" || this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList[index].ITEMNAME == null || this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList[index].ITEMNAME == undefined) {
        this.alertservice.error("Item Description is missing");
        return;
  
      }
      if (this.masterservice.nullToZeroConverter(this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList[index].QUANTITY) == 0) {
        this.alertservice.error("Quantity is missing");
        return;
  
      }
      if (this.masterservice.nullToZeroConverter(this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList[index].NONTAXABLE) == 0) {
        if (this.masterservice.nullToZeroConverter(this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList[index].TAXABLE) == 0) {
          this.alertservice.error("Taxable amount is missing");
          return;
  
        }
        if (this.masterservice.nullToZeroConverter(this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList[index].VAT) == 0) {
          this.alertservice.error("Vat amount is missing");
          return;
  
        }
  
      }
      if (this.masterservice.nullToZeroConverter(this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList[index].NETAMOUNT) == 0) {
        this.alertservice.error("Net Amount is missing");
        return;
  
      }

      this.RecalculateImportDetails();
      
      this.addRow();
      let nextindex = this.importrowindex+1;
      setTimeout(() => {
      document.getElementById('importItemname'+nextindex).focus();
      }, 200);
    }

    enterName(i){
      setTimeout(() => {
        document.getElementById("importQty"+i).focus();
      }, 200);
    }


    RecalculateImportDetails(){
      try{
        if (this._trnMainService.TrnMainObj.IMPORTDETAILS && this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList.length) {
          this._trnMainService.TrnMainObj.IMPORTDETAILS.TOTALQTY = 0
          this._trnMainService.TrnMainObj.IMPORTDETAILS.TOTALTAXABLE = 0;
          this._trnMainService.TrnMainObj.IMPORTDETAILS.TOTALNONTAXABLE = 0
          this._trnMainService.TrnMainObj.IMPORTDETAILS.TOTALVAT = 0;
          this._trnMainService.TrnMainObj.IMPORTDETAILS.NETAMOUNT = 0;
          this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList.forEach(x => {
            x.NETAMOUNT = this._trnMainService.nullToZeroConverter(x.NONTAXABLE) + this._trnMainService.nullToZeroConverter(x.TAXABLE) + this._trnMainService.nullToZeroConverter(x.VAT);
            this._trnMainService.TrnMainObj.IMPORTDETAILS.TOTALQTY = this._trnMainService.nullToZeroConverter(this._trnMainService.TrnMainObj.IMPORTDETAILS.TOTALQTY) + this._trnMainService.nullToZeroConverter(x.QUANTITY);
            this._trnMainService.TrnMainObj.IMPORTDETAILS.TOTALTAXABLE = this._trnMainService.nullToZeroConverter(this._trnMainService.TrnMainObj.IMPORTDETAILS.TOTALTAXABLE) + this._trnMainService.nullToZeroConverter(x.TAXABLE);
            this._trnMainService.TrnMainObj.IMPORTDETAILS.TOTALNONTAXABLE = this._trnMainService.nullToZeroConverter(this._trnMainService.TrnMainObj.IMPORTDETAILS.TOTALNONTAXABLE) + this._trnMainService.nullToZeroConverter(x.NONTAXABLE);
            this._trnMainService.TrnMainObj.IMPORTDETAILS.TOTALVAT = this._trnMainService.nullToZeroConverter(this._trnMainService.TrnMainObj.IMPORTDETAILS.TOTALVAT) + this._trnMainService.nullToZeroConverter(x.VAT);
            this._trnMainService.TrnMainObj.IMPORTDETAILS.NETAMOUNT = this._trnMainService.nullToZeroConverter(this._trnMainService.TrnMainObj.IMPORTDETAILS.NETAMOUNT) + this._trnMainService.nullToZeroConverter(x.NETAMOUNT);
          })
        }

      } catch(error){
        this.alertservice.error('Error in import details calculation: '+ error);
      }

    }

    onNonTaxableChange() {
      this.RecalculateImportDetails();
    }
    onTaxableChange() {
      this.RecalculateImportDetails();
    }
    onVatChange() {
      this.RecalculateImportDetails();
    }
    onQuantityChange() {
      this.RecalculateImportDetails();
    }

    deleteRow(index){
      if(confirm("Are you sure you want to delete this row ?")){
        this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList.splice(index,1);
        if(this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList.length === 0){
          this.addRow();
        }
      }
    }

    saveImportDetails(){
      console.log("save clicked", this._trnMainService.TrnMainObj.Mode);
      console.log(this._trnMainService.TrnMainObj.VCHRNO, this._trnMainService.TrnMainObj.PARAC);
      console.log("import details", this._trnMainService.TrnMainObj.IMPORTDETAILS);
      // console.log("import details array list", this.importDetailsArray);
      let bodyData = {
        VCHRNO: this._trnMainService.TrnMainObj.VCHRNO,
        SUPPLIER: this._trnMainService.TrnMainObj.PARAC,
        DOCUMENTNO: this._trnMainService.TrnMainObj.IMPORTDETAILS.DOCUMENTNO,
        LCNUM: this._trnMainService.TrnMainObj.IMPORTDETAILS.LCNUM,
        NETAMOUNT: this._trnMainService.TrnMainObj.IMPORTDETAILS.NETAMOUNT,
        // NONTAXABLE: this._trnMainService.TrnMainObj.IMPORTDETAILS.NONTAXABLE,
        // TAXABLE: this._trnMainService.TrnMainObj.IMPORTDETAILS.TAXABLE,
        // VAT: this._trnMainService.TrnMainObj.IMPORTDETAILS.VAT
      }
      // this.spinnerService.show("Saving import details.....")
      // this.masterservice.saveImportDetail(bodyData).subscribe( res=>{
      //   if(res.status == "ok"){
      //     this.spinnerService.hide();
      //     this.alertservice.success("Import details saved Successfully !");
      //     setTimeout(()=>{
      //       this.hide();
      //     },1200);
      //   }else{
      //     this.spinnerService.hide();

      //     this.alertservice.error(res.result);
      //   }
       
      // }, error=>{
      //   this.spinnerService.hide();
      //   this.alertservice.error(error);
      // });
    }
  }

