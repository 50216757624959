import {
  Component,
  Input,
  Output,
  EventEmitter,
  Injector,
  HostListener,
  OnChanges,
  ElementRef,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  AfterContentChecked,
  ChangeDetectionStrategy
} from '@angular/core';
import { PagedListingComponentBase } from '../../../paged-list-component-base';
import { first, tap } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '@environments/environment';
import { AlertService } from '@app/common/shared/alert/alert.service';

@Component({
  selector: 'generic-popup-grid',
  templateUrl: './generic-popup-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericPopUpComponent extends PagedListingComponentBase
  implements OnChanges, AfterViewInit,AfterContentChecked  {
  constructor(public injector: Injector,
    public dialog: MatDialog,
    public alertservice: AlertService,private ref: ChangeDetectorRef) {
    super(injector);
    if(this.selectedRowIndex == undefined ||this.selectedRowIndex == null){
      this.selectedRowIndex = 0;
    }
    // console.log("@@this.selectedRowIndex",this.selectedRowIndex)
  }
  /** List Declaration  */

  requestUrl = '';

  isActive: boolean = false;
  itemList: any[] = [];
  selectedRowIndex = 0;
  tabindex = 'list';
  hideGridOnDoubleClick = true;
  // for transaction filter
  billTo = '';
  tag = '';
  isForCancelOrder = false;

  /** Output  */

  @Output() onPopUpClose = new EventEmitter();
  @Output() onItemDoubleClick = new EventEmitter();
  @Output() onDeleteClicked = new EventEmitter();
  @Output() onPopUpHide = new EventEmitter();
  @Output() onPerformaPopUpHide = new EventEmitter();

  /** Input  */

  @Input() popupsettings: GenericPopUpSettings;
  @Input() summary: string;
  @ViewChild('inputBox') inputBox: ElementRef;
  ngAfterViewInit(): void {

    setTimeout(() => {
    this.filterValue.valueChanges.pipe(
      debounceTime(500)
    ).subscribe(data => {
      if(data.includes("&")){
        this.alertservice.warning("Cannot search using Special Characters.");
        this.ref.detectChanges();
        return;
      }
      this.refreshPage();
      this.refresh();
    });
  },10)
  }
  ngAfterContentChecked(): void {
    this.ref.detectChanges();
  }
  show(billTo: string = '', isForCancelOrder: boolean = false, tag: string = '', hideOnDoubleClick: boolean = true) {


    this.hideGridOnDoubleClick = hideOnDoubleClick;
    setTimeout(() => {
      this.inputBox.nativeElement.focus();
    }, 10);
    this.summary = null;
    this.billTo = billTo;
    this.isForCancelOrder = isForCancelOrder;
    this.itemList = [];
    this.isActive = true;
    this.selectedRowIndex = 0;
    this.tag = tag;
    setTimeout(() => {
      this.setFilterOption();
      // this.refreshPage();
      // this.refresh();
    }, 100);
  }

  setFilterOption() {
    if (this.popupsettings && this.popupsettings.columns.length) {
      const filterIndex = this.popupsettings.defaultFilterIndex ? this.popupsettings.defaultFilterIndex : 0;
      if (this.popupsettings.columns.length <= filterIndex) { return; }
      else if(this.popupsettings.title === 'PRODUCTS' && filterIndex == 1) this.filterOption = this.popupsettings.columns[0].key;
      else if( this.popupsettings.title === 'PRODUCTS' && filterIndex == 0) this.filterOption = '';
      else {
        this.filterOption = this.popupsettings.columns[filterIndex].key;
      }
      this.filterValue.setValue('');

    }
  }

  getData() {
    this.summary = null;
    this.selectedRowIndex = 0;
    let apiUrls = environment.apiUrl;
    const apiEndpoints = this.popupsettings.apiEndpoints;
    let apiUrl = `${apiUrls}${apiEndpoints}?currentPage=${
      this.pageNumber
      }&maxResultCount=${this.pageSize}`;

    if (this.billTo && this.billTo !== '' && this.billTo != null && this.billTo !== undefined) {
      apiUrl = apiUrl + `&billTo=${this.billTo}`;
    }
    if (this.tag && this.tag !== '' && this.tag != null && this.isTableLoading !== undefined) {
      apiUrl = apiUrl + `&tag=${this.tag}`;
    }
    if (this.isForCancelOrder) {
      apiUrl = apiUrl + `&isForCancelOrder=${this.isForCancelOrder}`;
    }

    this.requestUrl = this.getFilterOption(apiUrl);

    return this._http
      .get(this.requestUrl).
      pipe(first()).subscribe((res: any) => {
        // this.totalItems = res.totalCount ? res.totalCount : res.result.totalCount?res.result.totalCount: 0;
        // this.itemList = res.data ? res.data : res.result.data?res.result.data:[];
        this.totalItems = res.totalCount ? res.totalCount : 0;
        this.itemList = res.data ? res.data:[];
        this.itemList.forEach(function (item) {
          if (item.TRNDATE != null && item.TRNDATE !== undefined) {
            item.TRNDATE = item.TRNDATE.toString().substring(0, 10);
          }
          if (item.DATE != null && item.DATE !== undefined) {
            item.DATE = item.DATE.toString().substring(0, 10);
          }
          if(item.To_Date != null && item.To_Date != undefined){
            item.To_Date = item.To_Date.toString().substring(0, 10);
          }
          if(item.From_Date != null && item.From_Date != undefined){
            item.From_Date = item.From_Date.toString().substring(0, 10);
          }
        });
        if (this.itemList[this.selectedRowIndex] != null) {
          this.itemList[this.selectedRowIndex].itemSummary;
        }

        if (
          this.itemList.length > 0 &&
          this.selectedRowIndex === 0 &&
          this.itemList[this.selectedRowIndex].itemSummary
        ) {
          this.summary = this.itemList[this.selectedRowIndex].itemSummary;
        }
      });
  }

  hide() {
    console.log("hide")
    this.itemList = [];
    this.pageNumber = 1;
    this.totalItems = 0;
    this.isActive = false;
  }

  close(){
    // console.log("close called");
    this.onPopUpHide.emit(true);
  }
  hidePI(){
    // console.log("hidepopup Performa")
    this.onPerformaPopUpHide.emit(true);
  }
  




  triggerSearch() {
    if (
      this.filterOption == null ||
      this.filterOption === undefined ||
      this.filterOption === ''
    ) {
      return;
    }
    this.filterValue.setValue('');
    // this.refreshPage();
    // this.refresh();
  }

  singleClick(index) {
    this.selectedRowIndex = index;
    this.summary = this.itemList[index].itemSummary;
  }

  doubleClick($event) {
    this.onItemDoubleClick.emit($event);
    if (this.hideGridOnDoubleClick) {
      this.hide();
    }

  }

  @HostListener('document : keydown', ['$event'])
  @debounce(10)
  updown($event: KeyboardEvent) {
    if (!this.isActive) { return true; }
    if ($event.code === 'ArrowDown') {
      // $event.preventDefault();
      this.selectedRowIndex++;
      if (this.itemList[this.selectedRowIndex] != null) {
        this.summary = this.itemList[this.selectedRowIndex].itemSummary;
      }
      this.calculateTotalPages();
      if (
        this.selectedRowIndex === this.itemList.length &&
        this.pageNumber < this.totalPages
      ) {
        this.pageNumber = this.pageNumber + 1;
        this.refresh();
        this.selectedRowIndex = 0;
        this.itemListClosed();
      } else if (
        this.selectedRowIndex === this.itemList.length &&
        this.pageNumber === this.totalPages
      ) {
        this.selectedRowIndex = this.itemList.length - 1;
      }
    } else if ($event.code === 'ArrowUp') {
      // $event.preventDefault();
      this.selectedRowIndex--;
      if (this.itemList[this.selectedRowIndex] != null) {
        this.summary = this.itemList[this.selectedRowIndex].itemSummary;
      }
      if (this.selectedRowIndex === -1 && this.pageNumber > 1) {
        this.pageNumber = this.pageNumber - 1;
        this.refresh();
        this.selectedRowIndex = this.itemList.length - 1;
        this.itemListClosed();
      } else if (this.selectedRowIndex === -1 && this.pageNumber === 1) {
        this.selectedRowIndex = 0;
      }
    } else if (
      ($event.code === 'Enter' || $event.code === 'NumpadEnter') &&
      this.selectedRowIndex >= 0 &&
      this.selectedRowIndex < this.itemList.length
    ) {
      $event.preventDefault();
      if (this.filterOption === 'BARCODE') { } else {
        this.onItemDoubleClick.emit(this.itemList[this.selectedRowIndex]);
        this.hide();
        this.itemListClosed();
      }
    } else if ($event.code === 'Escape') {
      $event.preventDefault();
      this.hide();
      this.itemListClosed();
      this.close();
      this.hidePI();
    } else if ($event.code === 'ArrowRight') {
      $event.preventDefault();
      this.calculateTotalPages();
      if (this.pageNumber >= this.totalPages) {
        this.pageNumber = this.totalPages;
        return;
      }

      this.selectedRowIndex = 0;
      this.pageNumber = this.pageNumber + 1;
      this.refresh();
    } else if ($event.code === 'ArrowLeft') {
      $event.preventDefault();
      if (this.pageNumber <= 1) {
        this.pageNumber = 1;
        return;
      }
      this.selectedRowIndex = 0;
      this.pageNumber = this.pageNumber - 1;
      this.refresh();

    }
  }

  itemListClosed() {
    this.onPopUpClose.emit();
  }

  ngOnChanges(changes: any) {
    this.popupsettings = changes.popupsettings.currentValue;
  }

  // CreateCustomerPopup
  // onCreateCustomer() {
  //   const dialogBatchRef = this.dialog.open(CreateCustomerDialogComponent, {
  //     width: '360px',
  //   });
  // }

  onActionClicked(actionString: string, index: number) {
    if (actionString === '' || actionString == null || actionString === undefined) {
      return;
    } else {
      const action = actionString.toLowerCase();
      switch (action) {
        case 'delete':
          if (confirm(`Are you sure to ${action} this row ?.`)) {
            this.onDeleteClicked.emit(this.itemList[index]);
          }
          break;
        case 'cancel':
          if (confirm(`Are you sure to ${action} this row ?.`)) {
            this.onDeleteClicked.emit(this.itemList[index]);
          }
          break;
        default:
          break;
      }
    }
  }
}

export class GenericPopUpSettings {
  title?: string;
  apiEndpoints?: string;
  columns?: ColumnSettings[] = [];
  defaultFilterIndex?= 0;
  showActionButton?= false;
  actionKeys?: ActionKeySettings[] = [];
}

export class ColumnSettings {
  key?: string;
  title?: string;
  hidden?= false;
  noSearch?= false;
  alignment?:any;
  pipe?:any = false;
}
export class ActionKeySettings {
  icon?= '';
  text?= '';
  title?= '';

}

export function debounce(delay: number): MethodDecorator {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {

    const original = descriptor.value;
    const key = `__timeout__${propertyKey}`;

    descriptor.value = function (...args) {
      clearTimeout(this[key]);
      this[key] = setTimeout(() => original.apply(this, args), delay);
    };

    return descriptor;
  };
}
