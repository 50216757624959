<div class="customer_container" *ngIf="isActive">
    <div class="card">
      <div class="card-header">
        <h5 class="page-title">CREATE CUSTOMER PROFILE</h5>
        <div class="button-group">
          <button title="Save" type="submit" class="btn btn-info" (keydown.F6)="submit(form.value)" (click)="submit(form.value)" [disabled]="form.invalid"
            style="margin-right:2px">SAVE</button>
          <button type="button" title="reset" (click)="onclear()" (keydown.F3)="onclear()" class="btn btn-info"
            style="margin-right:2px">RESET</button>
          <button type="button" title="Cancel" class="btn btn-info" (click)="closeDialog()">CANCEL</button>
        </div>
      </div>
      <div class="card-body">
        <form class="row" [formGroup]="form" class="form-horizontal" style="margin-top: 15px;">
          <div class="form-group row">
            <label class="col-sm-3 form-control-label" style="margin-left: -12px;">NAME:</label>
            <div class="col-sm-5">
              <input #customerName type="text"  class="form-control" placeholder="NAME" formControlName="name" >
  
              <div class="invalid-feedback d-block"
                *ngIf="!isSubmitting && form.controls['name'].invalid && (form.controls['name'].dirty || form.controls['name'].touched)">
                <span *ngIf="form.controls['name'].errors.required">Name is required.</span>
              </div>
              <!-- Error before submitting -->
              <div class="invalid-feedback d-block" *ngIf="isSubmitting && form.controls['name'].invalid">
                <span *ngIf="form.controls['name'].errors.required">Name is required.</span>
              </div>
              <!-- Error after submitting -->
  
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 form-control-label" style="margin-left: -12px;">ADDRESS:</label>
            <div class="col-sm-5">
              <input type="text" class="form-control" placeholder="ADDRESS" formControlName="address">
  
              <div class="invalid-feedback d-block"
                *ngIf="!isSubmitting && form.controls['address'].invalid && (form.controls['address'].dirty || form.controls['address'].touched)">
                <span *ngIf="form.controls['address'].errors.required">Address is required.</span>
              </div>
              <!-- Error before submitting -->
              <!-- <div class="invalid-feedback d-block" *ngIf="isSubmitting && form.controls['address'].invalid">
                <span *ngIf="form.controls['address'].errors.required">Address is required.</span>
              </div> -->
              <!-- Error after submitting -->
  
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 form-control-label" style="margin-left: -12px;">PANNO:</label>
            <div class="col-sm-5">
              <input type="text" class="form-control" (keypress)="keyPress($event)" placeholder="PANNO" formControlName="pan" maxlength="9" minlength="9">
  
              <div class="invalid-feedback d-block"
                *ngIf="!isSubmitting && form.controls['pan'].invalid && (form.controls['pan'].dirty || form.controls['pan'].touched)">
                <span *ngIf="form.controls['pan'].errors.required">Pan No is required.</span>
              </div>
              <!-- Error before submitting -->
              <div class="invalid-feedback d-block" *ngIf="isSubmitting && form.controls['pan'].invalid">
                <span *ngIf="form.controls['pan'].errors.required">Pan No is required.</span>
              </div>
              <div class="invalid-feedback d-block"
              *ngIf="!isSubmitting && form.controls['pan'].invalid && (form.controls['pan'].dirty || form.controls['pan'].touched)"> 
                <span *ngIf="form.controls['pan'].errors.minlength || form.controls['pan'].errors.maxlength">PAN No must be 9 digit long.</span>
              </div> 
              <!-- Error after submitting -->
  
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 form-control-label" style="margin-left: -12px;">PHONE NO:</label>
            <div class="col-sm-5">
              <input type="text" class="form-control" placeholder="PHONE NO" formControlName="phoneNo">
  
              <!-- <div class="invalid-feedback d-block"
                *ngIf="!isSubmitting && form.controls['phoneNo'].invalid && (form.controls['phoneNo'].dirty || form.controls['phoneNo'].touched)">
                <span *ngIf="form.controls['phoneNo'].errors.required">Phone No is required.</span>
              </div> -->
              <!-- Error before submitting -->
              <!-- <div class="invalid-feedback d-block" *ngIf="isSubmitting && form.controls['phoneNo'].invalid">
                <span *ngIf="form.controls['phoneNo'].errors.required">Phone No is required.</span>
              </div> -->
              <!-- Error after submitting -->
  
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 form-control-label" style="margin-left: -12px;">MOBILE NO:</label>
            <div class="col-sm-5">
              <input type="text" class="form-control" (keypress)="keyPress($event)" placeholder="MOBILE NO" formControlName="mobNo" maxlength="10" minlength="10">
  
              <div class="invalid-feedback d-block"
                *ngIf="!isSubmitting && form.controls['mobNo'].invalid && (form.controls['mobNo'].dirty || form.controls['mobNo'].touched)">
                <span *ngIf="form.controls['mobNo'].errors.required">Mobile No is required.</span>
              </div>
              <!-- Error before submitting -->
              <div class="invalid-feedback d-block" *ngIf="isSubmitting && form.controls['mobNo'].invalid">
                <span *ngIf="form.controls['mobNo'].errors.required">Mobile No is required.</span>
              </div>
              <div class="invalid-feedback d-block"
              *ngIf="!isSubmitting && form.controls['mobNo'].invalid && (form.controls['mobNo'].dirty || form.controls['mobNo'].touched)"> 
                <span *ngIf="form.controls['mobNo'].errors.minlength || form.controls['mobNo'].errors.maxlength">Mobile No must be 10 digit long.</span>
              </div> 
              <!-- Error after submitting -->
  
            </div>
          </div>

          <div class="form-group row" *ngIf="form.controls['mobNo'].valid">
            <label class="col-sm-3 form-control-label" style="margin-left: -12px;">MOBILE NO REGISTER IN:</label>
            <div class="col-sm-5">
              <select class="form-control" formControlName="MOBILENO_REG_IN" style="padding: 0px 5px;">
                <option value=""></option>
                <option value="VIBER">Viber</option>
                <option value="WHATSAPP">Whatsapp</option>
              </select>  
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 form-control-label" style="margin-left: -12px;">Division:</label> 
            <div class="col-sm-5">
              <ng-multiselect-dropdown class="custom-asset-dropdown"
              [placeholder]="'Select Division'"
              [settings]="dropdownSettings"
              [data]="dropdownList" 
              (onSelect)="onItemSelect($event)" 
              (onSelectAll)="onSelectAll($event)"
              formControlName = "divisionMapping"
              ></ng-multiselect-dropdown>

            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 form-control-label" style="margin-left: -12px;">EMAIL:</label>
            <div class="col-sm-5">
              <input type="email" class="form-control" placeholder="EMAIL" formControlName="email">
  
              <!-- <div class="invalid-feedback d-block"
                *ngIf="!isSubmitting && form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)">
                <span *ngIf="form.controls['email'].errors.required">Email is required.</span>
              </div> -->
              <!-- Error before submitting -->
              <!-- <div class="invalid-feedback d-block" *ngIf="isSubmitting && form.controls['email'].invalid">
                <span *ngIf="form.controls['email'].errors.required">Email is required.</span>
              </div> -->
              <!-- Error after submitting -->
  
            </div>
          </div>
  
          <div class="form-group row" *ngIf="masterservice.userSetting.CREATE_CPROFILE_AS_MEMBER == 1 && masterservice.userSetting.ISDMSMASTER == 0">
            <div class="col-sm-5">
                <input type="checkbox" class="form-control" id="createMember" style="height: 59%;width: 5%;" formControlName="createMember" (change)="$event.target.checked?(form.value.createMember=1):(form.value.createMember=0)">
                <label class="form-control-label" style="width: 100%; margin-top: -3%; margin-left: 6%;">Create Customer Profile as Member Also</label>
            </div>
            <div class="col-sm-3"></div>
          </div>
        </form>
  
  
      </div>
    </div>
  
  </div>