import { Injectable, Injector, APP_INITIALIZER } from '@angular/core';
import * as data from '../../appConfig.json';
import { GlobalState } from './global.state';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { AuthenticationService } from '@app/_services';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AppConfigService {
    appSetting: IAppConfig;
    constructor(public _state: GlobalState,
        public http:HttpClient,
        public injector: Injector) {
        this._state.setGlobalSetting('apiUrl', [data.apiUrl]);
        environment.apiUrl = data.apiUrl;
    }

    checkAuthentication(){
        const router = this.injector.get(Router);
        const authService = this.injector.get(AuthenticationService);
        const promise = this.http.get(environment.apiUrl + '/checkLogin')
        .pipe(catchError(err => {
                return throwError(err);
            }))
            .toPromise()

        promise.then((res: any) => {
            if (res.status==='ok') {

            } else {
                localStorage.removeItem('USER_PROFILE');
                localStorage.removeItem('TOKEN');
                localStorage.removeItem('setting');
                router.navigate(['/login']);
                authService.setCurrentUserValue(null);

            }


        }, error => {
            localStorage.removeItem('USER_PROFILE');
            localStorage.removeItem('TOKEN');
            localStorage.removeItem('setting');
            router.navigate(['/login']);
            authService.setCurrentUserValue(null);

        })
        return promise
    }



    load() {
        const jsonfile = 'assets/appConfig.json';
        return new Promise<void>((resolve, reject) => {
            this.http.get(jsonfile).toPromise().then((response: IAppConfig) => {
                this.appSetting = <IAppConfig>response;
                 this._state.setGlobalSetting("apiUrl", this.appSetting['apiUrl']);
                environment.apiUrl = this.appSetting['apiUrl'];
                environment.AccountPort = this.appSetting['AccountPort'];
                resolve();
            }).catch((response: any) => {
                
                reject('could not load file cofig file');
            })
        })
    }

}


export function AppConfigFactory(configService: AppConfigService) {
    return () => configService.load().then(() => {
        //configService.checkAuthentication();
    });
}



export function init() {
    return {
        provide: APP_INITIALIZER,
        useFactory: AppConfigFactory,
        deps: [AppConfigService],
        multi: true
    }
}

const AppConfigModule = {
    init: init
}

export { AppConfigModule };

export interface IAppConfig {
    apiurl: string;
    AccountPort:string;
}